'use strict';

define('vb/private/vx/v2/endpointExtensionAdapter',[
  'vb/private/vx/baseExtensionAdapter',
  'vb/private/utils',
  'vb/private/constants',
], (
  BaseExtensionAdapter,
  Utils,
  Constants,
) => {
  /**
   * The extension adapter used to load extension from the extension manager using an endpoint URL
   */
  class EndpointExtensionAdapter extends BaseExtensionAdapter {
    initiateFetchManifest() {
      return Promise.resolve()
        .then(() => this.loadDigest([
          Constants.Digest.RUNTIME,
          Constants.Digest.REQUIREJS_INFO,
          Constants.Digest.APP_UI_INFO,
        ]))
        .then((manifest) => {
          this.log.info('Found', manifest.extensions.length, 'extension(s).');
          return manifest;
        });
    }

    /**
     * Load the specified types from the remote extension digest
     * @param {String[]} types
     * @returns {Promise<object>}
     */
    loadDigest(types) {
      const url = this.getUrl(types);
      this.log.info('Loading digest at', url.href, 'using extension manager v2.');

      return Promise.resolve()
        .then(() => this.getFetchManifestPromise(url.href))
        .then((results) => {
          const digest = (results && results.digest) || {};

          const manifest = {};
          if (types.includes(Constants.Digest.RUNTIME)) {
            // Extension Manager v2 returns digest.runtime[], so convert to v1 manifest.extensions[]
            manifest.extensions = this.readRuntimeDigest(digest[Constants.Digest.RUNTIME]);
          }
          if (types.includes(Constants.Digest.REQUIREJS_INFO)) {
            manifest.requirejsInfo = this.readRequirejsInfoDigest(digest[Constants.Digest.REQUIREJS_INFO]);
          }
          if (types.includes(Constants.Digest.APP_UI_INFO)) {
            manifest.appUiInfo = digest[Constants.Digest.APP_UI_INFO] || [];
          }
          if (types.includes(Constants.Digest.PWA_INFO)) {
            manifest.pwaInfo = digest[Constants.Digest.PWA_INFO] || [];
          }

          return manifest;
        });
    }

    /**
     * Builds paths from a vb-bundle format
     *
     * @param {Array[String]}  bundle
     * @param {Array[String]}  bundledResources
     * @param {Object}  files
     */
    buildPaths(extId, bundle, bundledResources, folders, currentPath = '') {
      if (!folders || typeof folders !== 'object') {
        return;
      }

      Object.keys(folders).forEach((name) => {
        // If "files" was a folder name, it's type would not be an array
        if (name === 'files' && Array.isArray(folders.files)) {
          folders.files.forEach((file) => {
            const exclIndex = file.indexOf('!');
            let plugin = '';
            if (exclIndex > 0) {
              plugin = file.substr(0, exclIndex + 1);
              // eslint-disable-next-line no-param-reassign
              file = file.substr(exclIndex + 1);
            }

            let path = `${currentPath}${file}`;
            bundledResources.push(path);

            // Remove file extension except for text plugin
            if (plugin !== 'text!') {
              path = path.substr(0, path.lastIndexOf('.'));
            }

            bundle.push(`${plugin}${extId}${path}`);
          });
        } else {
          this.buildPaths(extId, bundle, bundledResources, folders[name], `${currentPath}${name}/`);
        }
      });
    }

    /**
     * Convert a requirejs-info new vb-bundles format to an object of the old format
     */
    convertVbBundles(vbBundles) {
      const bundledResources = [];
      const bundles = {};

      vbBundles.forEach((bundle) => {
        if (!bundles[bundle.bundleId]) {
          bundles[bundle.bundleId] = [];
        }
        const extId = `vx/${bundle.bundleId.split('/')[1]}/`;
        this.buildPaths(extId, bundles[bundle.bundleId], bundledResources, bundle.files);
      });

      return { bundledResources, bundles };
    }

    /**
     * For each extension in the runtime digest, convert the vb-files object
     * to an array of files with path
     */
    readRuntimeDigest(runtime = []) {
      runtime.forEach((extension) => {
        const vbFiles = extension[Constants.Runtime.VB_FILES];

        if (vbFiles) {
          /* eslint-disable no-param-reassign */
          extension.files = this.convertVbFiles(vbFiles);

          delete extension[Constants.Runtime.VB_FILES];
          /* eslint-enable no-param-reassign */
        }
      });

      return runtime;
    }

    /**
     * Read the requirejs-info using the old or the new shrunk format
     */
    readRequirejsInfoDigest(requirejsInfo = []) {
      requirejsInfo.forEach((extension) => {
        const configurations = extension.metadata && extension.metadata.configurations;
        if (configurations) {
          const { build } = configurations;

          const vbBundles = build && build[Constants.RequirejsInfo.VB_BUNDLES];
          if (vbBundles) {
            const { bundledResources, bundles } = this.convertVbBundles(vbBundles);

            build.bundledResources = bundledResources;
            build.bundles = bundles;

            delete build[Constants.RequirejsInfo.VB_BUNDLES];
          }
        }
      });

      return requirejsInfo;
    }

    /**
     * Return a URL object initalized with the registry URL string
     *
     * @param {String[]} types
     * @return {URL}
     */
    getUrl(types) {
      const url = super.getUrl();

      const version = this.getExtensionVersion();
      // The version string has already been encoded, so don't encoded it again in
      // order to preserve the $ character
      url.pathname = `${Utils.addTrailingSlash(url.pathname)}${version || ''}`;

      url.search = `type=${types.join()}`;

      return url;
    }
  }

  return EndpointExtensionAdapter;
});

