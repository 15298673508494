'use strict';

define('vb/private/history',['vb/private/utils', 'vb/private/constants', 'vb/binding/expression',
], (Utils, Constants, Expression) => {
  const VB_STATE_PROPERTY = 'vbState';
  const REPLACE_STATE_OP = 'replaceState';
  const PUSH_STATE_OP = 'pushState';
  let paramsToIgnore;
  let cachedState;
  let url;
  let stateOp;
  let cleanUrlParameters;
  let navBackParams;
  let stateBeforeHistoryPop = null;
  let skipMode;
  let stack = [];
  let index = 0;
  let direction = Constants.NavigateDirection.FORWARD;
  let change = 0;
  let restoreState = false; // a flag used when history.back() is called to restore a cancelled forward button

  /**
   * Function to build a key to store the value of a container variable on the browser history state.
   * The scope name is needed to differentiate variable of the same name in different container
   *
   * @param      {String}  scopeName  The scope name
   * @param      {String}  namespace  The namespace (variables, constants, ...)
   * @param      {String}  name       The name
   * @return     {String}  The variable key.
   */
  const buildVariableKey = (scopeName, namespace, name) => `${scopeName}.${namespace}.${name}`;

  /**
   * Remove brackets ([] or {}) wrapping a request parameter so that it cannot be interpreted
   * as an expression
   *
   * @param  {String} urlParam  The url parameter
   * @return {String}
   */
  const cleanUrlParameter = (urlParam) => {
    let cleanUrlParam;
    if (typeof urlParam === 'string') {
      const expression = Expression.getExpression(urlParam);
      if (expression) {
        // Retrieve the string inside the expression and recurse in case brackets are nested
        // Expression.getExpression(urlParam) cannot be null or undefined because we know it
        // is an expression.
        cleanUrlParam = cleanUrlParameter(expression.trim());
      } else {
        cleanUrlParam = urlParam;
      }
    } else if (urlParam === null) {
      cleanUrlParam = null;
    }

    return cleanUrlParam;
  };

  /**
   * Add a parameter to the key/value map of URL parameters urlParams.
   * Support arrays when the parameter names shows more than once in the URL
   * @param {Object} urlParams
   * @param {String} key
   * @param {*} value
   */
  const calculateParamValue = (urlParams, key, value) => {
    const cleanValue = cleanUrlParameter(value);
    const existingValue = urlParams[key];
    // If a param of the same name exists, make an array for the value
    if (existingValue) {
      if (Array.isArray(existingValue)) {
        existingValue.push(cleanValue);
      } else {
        // eslint-disable-next-line no-param-reassign
        urlParams[key] = [existingValue, cleanValue];
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      urlParams[key] = cleanValue;
    }
  };

  /**
   * The History class has 3 purposes:
   *   1) abstract the window.history API
   *   2) defer any updates to the browser history until sync is called to minimized the amount
   *      of change to the browser history
   *   3) get around Google chrome throttling of the history state
   *
   * The state for VB is stored in a sub-property named 'vbState' to not conflict with other
   * state also stored in the browser history.
   */
  class History {
    /**
     * initialize the History class.
     *   - cached state is empty
     *   - any URL params are copied internally if clearParameter is truthy
     *   - URL params are removed from the search
     *   - operation is set to replaceState
     * @param  {function} popStateCallback register a callback on the history popstate event
     */
    static init(popStateCallback = () => {}) {
      // Add a listener to the popstate event so when navigating using the browser back button
      // or when history.back() is called we know to update the cached state
      window.onpopstate = (event) => {
        // When history.back() id called to cancel the browser forward button, the restoreFlag is
        // set to true so that we ignore it.
        if (restoreState) {
          restoreState = false;
          return;
        }

        History.popStateEventHandler(event);
        popStateCallback({ direction, change });
      };

      History.reset(false);
    }

    /**
     * Resets the given clear parameters.
     * Called internally and by unit tests.
     *
     * @param  {boolean} clearParameters if true, URL params are not stored internally
     */
    static reset(clearParameters) {
      cachedState = { id: Utils.generateUniqueId() };
      index = stack.push(Object.assign({}, cachedState)) - 1;

      url = new URL(window.location);

      const urlParams = {};
      paramsToIgnore = {};

      // Make a copy of the original query param and clean up the URL
      // This is so query param that are not part matching any fromUrl variable are removed
      // from the URL when that application is started
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of url.searchParams) {
        if (Constants.UrlParamsToIgnore.includes(key)) {
          // Keep track of any parameters to ignore, this will be used when resetting the URL
          paramsToIgnore[key] = value;
        } else {
          calculateParamValue(urlParams, key, value);
        }
      }

      stateOp = REPLACE_STATE_OP;
      skipMode = false;

      // erase all query params then restore the param to ignore
      History.resetUrlParameters();

      if (!clearParameters) {
        cleanUrlParameters = urlParams;
      }
    }

    /**
     * A listener for the popstate event so when navigating using the browser back button
     * or when history.back() is called we know to update the cached state.
     *
     * @param  {Object} event the popState event
     */
    static popStateEventHandler({ state }) {
      // Make a copy of the state before changing it. It will be used to restore state and url
      // if the navigation is cancelled.
      stateBeforeHistoryPop = {
        state: History.state,
        url: url.href,
      };

      // It is possible for event.state to be null, for example if a previous pushState used
      // null for the state argument or when a link with href="#" is clicked
      if (state === null) {
        const oldUrl = new URL(url.href);
        oldUrl.hash = '';
        const newUrl = new URL(window.location);
        newUrl.hash = '';
        const samePage = newUrl.href === oldUrl.href;

        // Do not loose internal state if navigating to the same page
        if (!samePage) {
          History.replaceState(null);
        }
        stack = [Object.assign({}, cachedState)];
        index = 0;
        direction = Constants.NavigateDirection.FORWARD;
      } else {
        const vbState = state[VB_STATE_PROPERTY];
        const newIndex = stack.findIndex(({ id }) => id === vbState.id);
        if (newIndex >= 0) {
          change = newIndex - index;
          if (newIndex - index > 0) {
            direction = Constants.NavigateDirection.FORWARD;
          } else {
            direction = Constants.NavigateDirection.BACKWARD;
            change = -change;
          }
          index = newIndex;
        } else {
          direction = Constants.NavigateDirection.FORWARD;
          change = 0;
          stack = [vbState];
          index = 0;
        }
        History.replaceState(vbState);
      }

      // Merge navBackParams on top of the cachedState inputParameters
      // This is to properly handle input parameters coming from the navigateBack action
      if (navBackParams) {
        cachedState.inputParameters = cachedState.inputParameters || {};
        Utils.cloneObject(navBackParams, cachedState.inputParameters);
        navBackParams = undefined;
      }

      // Create a new URL object using the existing browser URL
      url = new URL(window.location);
      // Repopulate the url params that will be used by manageInputParameters
      cleanUrlParameters = History.retrieveUrlParameters();
      stateOp = REPLACE_STATE_OP;
    }

    /**
     * Used by vbRouter to set the new URL
     *
     * @param {String} url The new value
     */
    static setUrl(newUrl) {
      if (!skipMode) {
        const search = url.search;
        url = new URL(newUrl);
        url.search = search;
      }
    }

    /**
     * Reset the pathname part of the current url.
     * This is needed after the router navigated event to update the url
     * with the new path set by the router. Input parameter need to be
     * kept around to initialize the fromUrl variable.
     */
    static resetUri() {
      if (!skipMode) {
        const search = url.search;
        const hash = url.hash;
        url = new URL(window.location);
        url.search = search;
        url.hash = hash;
      }
    }

    /**
     * Parse the URL parameters and return an object which properties are parameter names
     * making sure values of URL parameters are not expressions.
     *
     * @return {Object<String|Array[String]>}  The url parameters.
     */
    static retrieveUrlParameters() {
      const urlParams = {};

      url.searchParams.forEach((value, key) => {
        if (!Object.keys(paramsToIgnore).includes(key)) {
          calculateParamValue(urlParams, key, value);
        }
      });

      return urlParams;
    }

    /**
     * Reset the URL parameters except for the one to ignore
     * This is used before navigating to clean up for the new state.
     */
    static resetUrlParameters() {
      if (!skipMode) {
        url.search = '';
        // Restore the param to ignore
        Object.keys(paramsToIgnore).forEach((param) => {
          url.searchParams.set(param, paramsToIgnore[param]);
        });
        cleanUrlParameters = {};
      }
    }

    static setSkipMode(flag) {
      skipMode = flag;
    }

    static getSkipMode() {
      return skipMode;
    }

    /**
     * Retrieve the current cached state. Never returns undefined or null.
     *
     * @return {Object} the current state
     */
    static get state() {
      return Utils.cloneObject(cachedState);
    }

    /**
     * Set the browser state operation to be pushState
     */
    static pushState() {
      stateOp = PUSH_STATE_OP;
    }

    /**
     * Replace the currently cached browser state and URL.
     * @param  {Object} state the new state
     */
    static replaceState(state) {
      cachedState = state ? Utils.cloneObject(state) : {
        id: Utils.generateUniqueId(),
      };
    }

    /**
     * Adds an url parameter if the value is defined
     *
     * @param {String} name
     * @param {String} value
     */
    static addUrlParameter(name, value) {
      if (!skipMode) {
        if (value !== undefined) {
          url.searchParams.set(name, value);
          // searchParams API replaces space character with '+' but some VB app
          // expect %20 which is a valid alternative
          url.search = url.search.replaceAll('+', '%20');
        }
      }
    }

    /**
     * Sets the url parameter. If the value is undefined, the param is
     * removed from the URL
     *
     * @param {String} name
     * @param {String} value
     */
    static setUrlParameter(name, value) {
      if (!skipMode) {
        if (value !== undefined) {
          if (Array.isArray(value)) {
            // Remove existing entry
            url.searchParams.delete(name);
            // and replace with a new one
            value.forEach((paramValue) => {
              url.searchParams.append(name, paramValue);
            });
          } else {
            url.searchParams.set(name, value);
          }
        } else {
          url.searchParams.delete(name);
        }
        // searchParams API replaces space character with '+' but some VB app
        // expect %20 which is a valid alternative
        url.search = url.search.replaceAll('+', '%20');
      }
    }

    /**
     * Gets the url parameter clean value (no expression backet)
     *
     * @param  {String} name
     * @return {String} clean parameter value
     */
    static getUrlParameter(name) {
      return cleanUrlParameters[name];
    }

    /**
     * Retrieve the search section of the current URL
     * @return {String}
     */
    static getSearch() {
      return url.search;
    }

    /**
     * Replace the search section of the current URL
     * @param {String} search
     */
    static setSearch(search = '') {
      if (!skipMode) {
        url.search = search;
      }
    }

    /**
     * Retrieve the hash section of the cached URL
     * @return {String} hash
     */
    static getHash() {
      return url.hash;
    }

    /**
     * Replace the hash section of the cached URL
     * @param {String} hash
     */
    static setHash(hash = '') {
      url.hash = hash;
    }

    /**
     * Store a copy of the input parameters in history.
     * It is the options.params argument of the navigateToPage action. This is needed
     * in two situation:
     *   1) When navigating back to this page and initializing the page input parameter.
     *   2) When navigating to the same page to compare if the input parameters are different.
     *
     * @param {Object} inputParameters an object where the property keys are params name and
     * the property values the params values.
     */
    static setInputParameters(inputParameters) {
      cachedState.inputParameters = {};
      Utils.cloneObject(inputParameters, cachedState.inputParameters);
    }

    static getInputParameters() {
      return cachedState.inputParameters || {};
    }

    /**
     * Set the navBackParams object.
     * This is used by the navigateBackAction to set input parameters on the page it is
     * navigating back to.
     * @param {Object} params
     */
    static setNavNavBackParams(params) {
      navBackParams = params;
    }

    static setPagePath(pagePath) {
      cachedState.pagePath = pagePath;
    }

    static getPagePath() {
      return cachedState.pagePath;
    }

    /**
     * Used to store history persisted variables.
     * Variable state is stored in state.variables[${namespace}.${name}]
     *
     * @param {String} scopeName
     * @param {String} namespace
     * @param {String} name
     * @param {String} the value of the variable in a serializable form (string)
     */
    static setVariable(scopeName, namespace, name, value) {
      const key = buildVariableKey(scopeName, namespace, name);

      if (value !== undefined) {
        if (!cachedState.variables) {
          cachedState.variables = {};
        }
        cachedState.variables[key] = value;
      } else if (cachedState.variables) {
        delete cachedState.variables[key];
      }
    }

    /**
     * Used to retrieve history persisted variables.
     * Return undefined, if the variable value is not found
     *
     * @param  {String} scopeName
     * @param  {String} namespace
     * @param  {String} name
     * @return {String} the value of the variable in a serializable form (string)
     */
    static getVariable(scopeName, namespace, name) {
      return cachedState.variables && cachedState.variables[buildVariableKey(scopeName, namespace, name)];
    }

    static getVariables() {
      return cachedState.variables || {};
    }

    /**
     * Reset page history persisted variable from the cache
     * This is a local function but need to be called from tests
     */
    static resetPageVariables() {
      // VBS-34264 - Temporarily comment out this, reverting part of VBS-30648
      // Object.keys(History.getVariables()).forEach((key) => {
      //   const scopeName = key.substring(0, key.indexOf('.'));
      //   const className = scopeName.substring(0, scopeName.indexOf('/'));
      //   // Matches "Page" and "PackagePage"
      //   if (className.indexOf('Page') >= 0) {
      //     delete cachedState.variables[key];
      //   }
      // });
    }

    /**
     * Stores a value in the history state using a string key
     *
     * @param {String}  key     The key
     * @param {Object}  value   The value
     */
    static storeInState(key, value) {
      if (value !== undefined) {
        cachedState.storage = cachedState.storage || {};
        cachedState.storage[key] = value;
      } else if (cachedState.storage) {
        delete cachedState.storage[key];
      }
    }

    /**
     * Retrieves a value from the history state using a string key
     *
     * @param  {String}  key     The key
     * @return {Object}  The value
     */
    static retrieveFromState(key) {
      return cachedState.storage && cachedState.storage[key];
    }

    /**
     * Execute the back on browser history API
     */
    static back() {
      window.history.back();
    }

    /**
     * Apply cached changes to the browser history or URL.
     * @return {Promise} a promise that resolves when the browser history changed
     */
    static sync() {
      const { href } = url;

      stateBeforeHistoryPop = null;

      if (stateOp === REPLACE_STATE_OP) {
        let historyState = window.history.state;
        const vbState = historyState && historyState[VB_STATE_PROPERTY];

        if (href !== window.location.href || Utils.diff(cachedState, vbState)) {
          // An empty cacheState is the same as undefined
          if (Object.keys(cachedState).length > 0) {
            if (!historyState) {
              historyState = {};
            }
            // Our state is always store in the property 'vbState' of the history state
            historyState[VB_STATE_PROPERTY] = cachedState;
          } else if (historyState) {
            delete historyState[VB_STATE_PROPERTY];
          }

          return Utils.changeBrowserState(historyState, href, stateOp)
            .then(() => {
              stack[index] = Object.assign({}, cachedState);
            });
        }
        return Promise.resolve();
      }
      // In the pushState case, don't need to merge with existing state
      cachedState.id = Utils.generateUniqueId();
      // Instead of getting a new state and copying the history persisted variables for flow, app, and page
      // when navigationg to the same page, current state is kept and the page persisted variables are removed.
      // This is so we will not find them when navigating to this page later
      if (href !== window.location.href) {
        History.resetPageVariables();
      }
      return Utils.changeBrowserState({ [VB_STATE_PROPERTY]: cachedState }, href, stateOp).then(() => {
        // Remove all elements in the stack after the current one and add the new one to the end
        stack.splice(index + 1, stack.length - 1 - index, Object.assign({}, cachedState));
        index = stack.length - 1;
        stateOp = REPLACE_STATE_OP;
      });
    }

    static getStateBeforeHistoryPop() {
      return stateBeforeHistoryPop || {};
    }

    /**
     * Restore the URL and the history state to the value before the browser back/forward button.
     * This is to be used when a navigation initiated by a browser back/forward button is cancelled.
     * @return {Promise} a promise that resolve when the browser history is restored
     */
    static restoreStateBeforeHistoryPop(eventPayload) {
      if (stateBeforeHistoryPop
        // Only restore the state if the the navigation was initiated from a browser back or forward button
        // with only one step change on the stack
        && eventPayload && eventPayload.origin === Constants.NavigateOrigin.POPSTATE
        && eventPayload.canBeCanceled) {
        if (eventPayload.direction === Constants.NavigateDirection.BACKWARD) {
          // On back button the browser always pop the history stack, so to restore the stack on cancel,
          // the state is pushed on the stack.
          cachedState = stateBeforeHistoryPop.state;
          url = new URL(stateBeforeHistoryPop.url);

          return Utils.changeBrowserState({ [VB_STATE_PROPERTY]: cachedState },
            stateBeforeHistoryPop.url, PUSH_STATE_OP)
            .then(() => {
              index += eventPayload.steps;
              stateOp = REPLACE_STATE_OP;
            });
        }

        if (eventPayload.direction === Constants.NavigateDirection.FORWARD) {
          // On forward button the browser always move the history stack forward, so to restore the stack on cancel,
          // calls history.back.
          // Use the restoreState marker to skip the normal popstate processing in that case
          restoreState = true;
          index -= eventPayload.steps;
          History.back();
        }
      }

      return Promise.resolve();
    }

  // end of history class
  }

  return History;
});

