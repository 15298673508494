/* eslint-disable class-methods-use-this, no-unused-vars */

'use strict';

define('vb/private/helpers/runtimeEnvironment',[
  'vb/private/configLoader',
  'vb/private/log',
  'vb/private/utils',
  'vb/private/services/servicesLoader',
  'vb/private/services/swaggerUtils',
  'vb/private/services/trapData',
], (ConfigLoader, Log, Utils, ServicesLoader, SwaggerUtils, TrapData) => {
  // the amount of time in milliseconds to wait for the user session to refresh from the hidden iframe
  const REFRESH_AUTH_TOKEN_TIMEOUT = 3000;

  const badStringUrlSegmentRegex = /\/(undefined|null)\//gi;

  const logger = Log.getLogger('/vb/private/helpers/runtimeEnvironment');

  /**
   * A facade for how we get application artifacts, this class is used by the runtime to perform
   * tasks that are environment specific such as loading files and logging. You can provide a
   * subclass implementation by supply the following mapping in your requirejs.config
   * before vb/bootstrap is called to load the runtime:
   *
   * map: {
   *  '*': {
   *    vbRuntimeEnvironmentClass: 'DtRtEnvironment'
   *  }
   * }
   *
   * TODO: The contract for this class will be evolving so it's subject to change in the future.
   */
  class RuntimeEnvironment {
    /**
     * Return the extension manifest of the extension currently being edited in DT.
     * {
     *   id: 'extA',
     *   name: 'My Extension A',
     *   version: '1.2.3',
     *   files: [
     *     'base/dynamicLayouts/pet/findByStatus/layout-x.json',
     *     'base/pages/shell-page-x.json',
     *     'base/pages/shell-page-x.js',
     *   ],
     *   url: {
     *     baseUrl: 'extA/sources/ui/extension1/',
     *   },
     * }
     * @return {Promise} a promise that resolve to null or object representing an extension
     * manifest as defined above
     */
    getExtensionManifest() {
      return Promise.resolve(null);
    }

    /**
     * Return the type information of the security provider user info.
     * @return {Promise} a promise that resolve to the user info type
     */
    getUserInfoType() {
      return ConfigLoader.loadSecurityProvider()
        .then((securityProvider) => securityProvider && securityProvider.constructor.getUserInfoType());
    }

    /**
     * Give the ability to set the RT to run without loading App UIs from the registry
     * This is needed in certain preview mode.
     * @return {Promise.<Boolean>} a promise resolving to true to disable loading App UI
     */
    disableAppUis() {
      return Promise.resolve(false);
    }

    /**
     * Return the application descriptor as a JSON object.
     *
     * @returns {Promise.<T>|*|Promise}
     */
    getApplicationDescriptor(flowLocator) {
      return Utils.loadAndParse(`${flowLocator}-flow.json`);
    }

    /**
     * Return the application functions.
     *
     * @returns {Promise.<T>|*|Promise}
     */
    getApplicationFunctions(flowLocator) {
      return Utils.getResource(`${flowLocator}-flow`);
    }

    /**
     * Return the flow descriptor as a JSON object.
     *
     * @param flowLocator the location of the flow, e.g., flow1 or flow1/flow2
     */
    getFlowDescriptor(flowLocator) {
      return Utils.loadAndParse(`${flowLocator}-flow.json`);
    }

    /**
     * Return the flow functions.
     *
     * @param flowLocator the location of the flow, e.g., flow1 or flow1/flow2
     * @returns {*}
     */
    getFlowFunctions(flowLocator) {
      return Utils.getResource(`${flowLocator}-flow`);
    }

    /**
     * Return the extension descriptor as a flow JSON object.
     *
     * @param extensionLocator the location of the extension
     * @returns {Promise.<T>|*|Promise}
     */
    getFlowExtensionDescriptor(extensionLocator) {
      return Utils.loadAndParse(`${extensionLocator}-flow-x.json`);
    }

    /**
     * Return the flow extension functions.
     *
     * @param {String} extensionLocator the location of the flow extension, e.g., flow1 or flow1/flow2
     * @returns {Promise.<T>|*|Promise}
     */
    getFlowExtensionFunctions(extensionLocator) {
      return Utils.getResource(`${extensionLocator}-flow-x`);
    }

    /**
     * Return the App UI descriptor as a JSON object.
     *
     * @param packageLocator the location of the App UI
     * @returns {Promise.<Object}
     */
    getAppUiDescriptor(packageLocator) {
      return Utils.loadAndParse(`${packageLocator}.json`);
    }

    /**
     * Return the App UI functions.
     *
     * @param packageLocator the location of the App UI
     * @returns {Object}
     */
    getAppUiFunctions(packageLocator) {
      return Utils.getResource(packageLocator);
    }

    /**
     * Return the App UI extension descriptor as a JSON object.
     *
     * @param packageLocator the location of the App UI
     * @returns {Object}
     */
    getAppUiExtensionDescriptor(packageLocator) {
      return Utils.loadAndParse(`${packageLocator}-x.json`);
    }

    /**
     * Return the App UI extension functions.
     *
     * @param {String} extensionLocator the location of the app package extension
     * @returns {Promise.<T>|*|Promise}
     */
    getAppUiExtensionFunctions(extensionLocator) {
      return Utils.getResource(`${extensionLocator}-x`);
    }

    /**
     * Return the page descriptor as a JSON object.
     *
     * @param pageLocator the location of the page, e.g., page1 or flow1/page1
     * @returns {Promise.<T>|*|Promise}
     */
    getPageDescriptor(pageLocator) {
      return Utils.loadAndParse(`${pageLocator}-page.json`);
    }

    /**
     * Return the page templage.
     *
     * @param pageLocator the location of the page, e.g., page1 or flow1/page1
     * @returns {Promise.<T>|*|Promise}
     */
    getPageTemplate(pageLocator) {
      return Utils.getResource(`text!${pageLocator}-page.html`);
    }

    /**
     * Return the page functions.
     *
     * @param pageLocator the location of the page, e.g., page1 or flow1/page1
     * @returns {Promise.<T>|*|Promise}
     */
    getPageFunctions(pageLocator) {
      return Utils.getResource(`${pageLocator}-page`);
    }

    /**
     * Return the extension descriptor as a page JSON object.
     *
     * @param extensionLocator the location of the extension
     * @returns {Promise.<T>|*|Promise}
     */
    getPageExtensionDescriptor(extensionLocator) {
      return Utils.loadAndParse(`${extensionLocator}-page-x.json`);
    }

    /**
     * Return the page extension functions.
     *
     * @param {String} extensionLocator the location of the page extension, e.g., page1 or flow1/page1
     * @returns {Promise.<T>|*|Promise}
     */
    getPageExtensionFunctions(extensionLocator) {
      return Utils.getResource(`${extensionLocator}-page-x`);
    }

    /**
     * Return the fragment descriptor as a JSON object.
     *
     * @param fragmentLocator the location of the fragment, e.g., fragments/frag1/frag1
     * @returns {Promise.<T>|*|Promise}
     */
    getFragmentDescriptor(fragmentLocator) {
      return Utils.loadAndParse(`${fragmentLocator}-fragment.json`);
    }

    /**
     * Return the fragment templage.
     *
     * @param fragmentLocator the location of the fragment, e.g., fragments/frag1/frag1
     * @returns {Promise.<T>|*|Promise}
     */
    getFragmentTemplate(fragmentLocator) {
      return Utils.getResource(`text!${fragmentLocator}-fragment.html`);
    }

    /**
     * Return the fragment functions.
     *
     * @param fragmentLocator the location of the fragment, e.g., fragments/frag1/frag1
     * @returns {Promise.<T>|*|Promise}
     */
    getFragmentFunctions(fragmentLocator) {
      return Utils.getResource(`${fragmentLocator}-fragment`);
    }

    /**
     * Return the extension descriptor as a fragment JSON object.
     *
     * @param extensionLocator the location of the extension
     * @returns {Promise.<T>|*|Promise}
     */
    getFragmentExtensionDescriptor(extensionLocator) {
      return Utils.loadAndParse(`${extensionLocator}-fragment-x.json`);
    }

    /**
     * Return the fragment extension functions.
     *
     * @param fragmentLocator the location of the fragment, e.g., fragments/frag1/frag1
     * @returns {Promise.<T>|*|Promise}
     */
    getFragmentExtensionFunctions(fragmentLocator) {
      return Utils.getResource(`${fragmentLocator}-fragment-x`);
    }

    /**
     * Load the object containing the string map for the current locale (as determined by JET).
     * This uses the JET ojL10N plugin to load "version 1" strings.
     *
     * @param path
     * @returns {Promise<Object>} map of keys to strings
     *
     * @see getV2Strings
     */
    getLocaleBundle(path) {
      return Utils.getResource(`ojL10n!${path}`);
    }

    /**
     * @see getLocaleBundle
     *
     * returns an object with one property:
     * - functions: the compiled JET string bundle
     * (Note: previously V2Strings mapped the key functions to keys (in a 'wrappers' property of the return value.
     * This allowed use of V1 EL syntax for V2 strings.  However, it is decided that V2 strings should use the V2
     * EL syntax where all string keys are functions whether they have parameters or not.
     *
     * The new bundles use a (‘normalized’) locale in the path to the bundle. The paths to the bundles are explicit
     * expressions that include some agreed-upon value for locale (DT is defining this).
     * At runtime, there should always be a valid locale path segment.
     * But at designtime, there is a concept of a ‘base bundle’ that serves at the default.
     * And this is selected by setting the locale value to undefined. Instead of requiring every path expression
     * to use a ternary operator to create valid urls, its easier to just strip the invalid segment.
     *
     * @param path {string}
     * @param declaration the original, unevaluated declaration (currently not used by runtime)
     *
     * @returns {{ functions: object }};
     */
    getV2Strings(path, declaration) {
      const sanitized = path.replace(badStringUrlSegmentRegex, '/');
      return Utils.getResource(sanitized)
        .then((functions) => ({
          // the string bundle functions
          functions,
        }));
    }

    /**
     * generic method to load text files
     * @param path
     * @returns {Promise<string>} contains the file contents
     */
    getTextResource(path) {
      return Utils.getTextResource(path);
    }

    /**
     * generic method to load source modules
     * @param {String} path
     * @returns {Promise|*|Promise<string>}
     */
    getModuleResource(path) {
      return Utils.getResource(path);
    }

    /**
     * generic method to load text files
     * @param path
     * @returns {Promise<string>} contains the file contents
     */
    getExtensionTextResource(path) {
      return Utils.getTextResource(path);
    }

    /**
     * generic method to load source modules
     * @param {String} path
     * @returns {Promise|*|Promise<string>}
     */
    getExtensionModuleResource(path) {
      return Utils.getResource(path);
    }

    /**
     * Return an array of paths to modules the runtime should preload before the application starts.
     *
     * @returns {Promise}
     */
    getModulesToPreload() {
      return Promise.resolve([]);
    }

    /**
     * This method is called when an error is logged by the logger. The error can either be a string or an instance of
     * Error.
     *
     * @param args can be either a string or an instance of Error or a mixture
     */
    notifyError(...args) {}

    /**
     * A callback to veto navigation
     *
     * @param  {String} currentPath     the path of the current page
     * @param  {String} destinationPath the path of the destination page
     * @return {Promise} a promise which resolves to true if navigation can proceed
     */
    canNavigateToPage(currentPath, destinationPath) {
      return Promise.resolve(true);
    }

    /**
     * A callback to veto navigation
     *
     * @param  {String} currentPath     the path of the current page
     * @param  {Object} options         the set of option definining the navigation
     * @return {Promise} a promise which resolves to true if navigation can proceed
     */
    canNavigate(currentPath, options) {
      return Promise.resolve(true);
    }

    /**
     * A callback to veto navigation to an URL
     *
     * @param  {String} currentPath  the path of the current page
     * @param  {String} url          the destination URL
     * @return {Promise} a promise which resolves to true if navigation can proceed
     */
    canNavigateToUrl(currentPath, url) {
      return Promise.resolve(true);
    }

    /**
     * A callback to veto navigation back to the previous URL in the browser
     * history.
     *
     * @param  {String} currentPath  the path of the current page
     * @return {Promise} a promise which resolves to true if navigation can proceed
     */
    canNavigateBack(currentPath) {
      return Promise.resolve(true);
    }

    /**
     * A callback to veto openUrl action.
     * @param  {String} currentPath the path of the current page
     * @param  {String} url         the destination URL
     * @param  {String} windowName  the windowName param of the openUrl call. See Router.openUrl()
     * @return {Promise} a promise which resolves to true if openUrl can proceed
     */
    canOpenUrl(currentPath, url, windowName) {
      return Promise.resolve(true);
    }

    /**
     * Returns a promise of user roles that override the logged-in user roles. The promise should resolve
     * to undefined if the logged-in user roles should not be overridden.
     *
     * @return {Promise<string[]>}
     */
    getUserRolesOverride() {
      return Promise.resolve();
    }

    /**
     * Returns a promise of user permissions that override the logged-in user permissions. The promise
     * should resolve to undefined if the logged-in user permissions should not be overridden.
     *
     * @return {Promise<string[]>}
     */
    getUserPermissionsOverride() {
      return Promise.resolve();
    }

    /**
     * Save the given parameters that can be later retrieved by calling loadInputParameters. This method is
     * overridden by the DT to pass parameters when navigating between two pages which are loaded in different
     * iframes.
     *
     * Note: You need to call parameters.plainParams to get the parameters as a plain object.
     *
     * @param parameters an object with a plainParams getter for retrieving the parameters as a plain object.
     */
    saveInputParameters(parameters) {}

    /**
     * Load the parameters saved by saveInputParameters. This method is overridden by the DT to pass parameters when
     * navigating between two pages which are loaded in different iframes.
     */
    loadInputParameters() {}

    /**
     * Return a promise that resolves to a requirejs config object that will be set on the service worker.
     * For example:
     * {
     *   paths: {
     *     vbdtsw: 'some url'
     *   }
     * }
     *
     * @returns {Promise<Object>}
     */
    getServiceWorkerRequireConfig() {
      return Promise.resolve({});
    }

    /**
     * Return a promise that resolves to an array of plugin info. A plugin info can be either a url
     * string or and object containing url and params properties. The params property will be passed
     * to the constructor of the plugin. For example:
     *
     * [
     *   'vbsw/plugin1',
     *   {
     *     url: 'vbsw/plugin2',
     *     params:  {
     *       foo: 'bar'
     *     }
     *   }
     * ]
     *
     * @returns {Promise<Array>}
     */
    getServiceWorkerPlugins() {
      return Promise.resolve([]);
    }

    /**
     * Handle user session expiry. Note that if the session is refreshed successfully, the returned promise should
     * resolve to true so the originating request is retried. Otherwise, resolve the promise to false or reject
     * the promise to provide an error message.
     *
     * @returns {Promise}
     */
    sessionExpired(loginUrl) {
      if (!loginUrl) {
        return Promise.reject('Cannot refresh session without a login url.');
      }

      return new Promise((resolve, reject) => {
        // get the hidden iframe for refreshing the auth token
        const refreshTokenIframe = document.getElementById('vb-refresh-access-token-iframe');

        if (refreshTokenIframe) {
          let timeout;

          // listener for the event from the hidden iframe
          const listener = (e) => {
            if (e.origin === window.location.origin && e.data.method === 'vbRefreshAuthToken') {
              // clear the timeout and remove the listener
              clearTimeout(timeout);
              window.removeEventListener('message', listener);

              // return true so we can retry the request
              resolve(true);
            }
          };
          window.addEventListener('message', listener);

          // redirect the hidden iframe to the login url
          refreshTokenIframe.src = loginUrl;

          timeout = setTimeout(() => {
            window.removeEventListener('message', listener);
            reject('Refreshing auth token timed out.');

            // redirect the application to the login url
            window.location.href = loginUrl;
          }, REFRESH_AUTH_TOKEN_TIMEOUT);
        } else {
          // since there's no hidden iframe, simply reject the originating request and redirect the application
          // to the login url
          reject('Redirecting the application to the login url');
          window.location.href = loginUrl;
        }
      });
    }

    /**
     * Returns a promise of override for the service swagger extension. For example,
     * {
     *  'dt-serviceAuthentication': {
     *     implicitFlow: false,
     *  },
     *}
     *
     * @returns {Promise<Object>}
     */
    getServiceExtensionOverride() {
      return Promise.resolve(null);
    }

    /**
     * abstraction for getting service.json
     *
     * @param path must be previously prepended with Router.baseUrl, if it is a relative path
     * @param requestInit optional, and configuration for the fetch() Request object used to get the definition
     * @returns {Promise} resolved with the definition, or rejected
     */
    getServiceDefinition(path, requestInit = {}) {
      return ServicesLoader.loadWithTimeout(path, requestInit);
    }

    /**
     * load a transform (requireJS) module source
     * @param path
     * @returns {Promise}
     */
    getTransformsSource(path) {
      return Utils.getResource(path);
    }

    /**
     * load the catalog.json, which handles the "vb-catalog://" protocol references (see catalogHandler.js)
     * @param path
     * @returns {Promise<string>} resolve with the JSON string for the object
     */
    getServiceExtensionCatalog(path) {
      return Utils.getResource(`text!${path}`);
    }

    /**
     * Local server overrides for backends and services.
     * By default we read the value from vbInitConfig[CATALOG_OVERRIDE].
     *
     * @return {Promise<string|Object>}
     */
    getCatalogOverride() {
      if (!this._catalogOverrides) {
        this._catalogOverrides = Promise.resolve()
          .then(() => (globalThis.vbInitConfig && globalThis.vbInitConfig.CATALOG_OVERRIDE));
      }
      return this._catalogOverrides;
    }

    /**
     * Returns information about server overrides fetched from the RIS TRAP service endpoint
     * @returns {Promise<Object>}
     */
    getTrapCatalogOverrides() {
      if (!this._trapCatalogOverrides) {
        this._trapCatalogOverrides = Promise.resolve()
          .then(() => TrapData.getTrapData().fetchTrapServiceServers())
          .then((response) => {
            if (response) {
              if (response.ok) {
                return response.text();
              }
              logger.warn('failed to load servers details from TRAP service', response.url, response.status);
            }
            return undefined;
          })
          .then((trapServersText) => trapServersText && SwaggerUtils.parseServiceText(trapServersText))
          .catch((error) => {
            logger.warn('failed to load servers details from TRAP service', error);
            return undefined;
          });
      }
      return this._trapCatalogOverrides;
    }

    /**
     * Gets configuration object providing information for making calls to the VB TRAP endpoints,
     * used for local server overrides.
     * @returns {Promise<Object>}
     */
    getLocalTrapConfiguration() {
      if (!this._localTrapConfig) {
        this._localTrapConfig = Promise.resolve()
          .then(() => (globalThis.vbInitConfig && globalThis.vbInitConfig.TRAP_SERVICE_OVERRIDES));
      }
      return this._localTrapConfig;
    }

    /**
     * load a programmatic endpoint plugin (requireJS) module source
     * @param path
     * @returns {Promise}
     */
    getEndpointPlugin(path) {
      return Utils.getResource(path);
    }

    /**
     * @returns {boolean}, if open tracing should be enabled in an environment and RT should try to initialize
     * a Tracer. For example, it should be true in DT preview, but false in DT page builder.
     */
    isOpenTraceEnabled() {
      return true;
    }

    /**
     * used to add DT-specific information to an object.
     * The default runtime implementation does nothing
     *
     * @param target {*} anything; initial use-case is something bound to a component, that the DT can access.
     * @param name {string}
     * @param value {*}
     * @returns {boolean} should return true if annotated
     */
    annotate(target, name, value) {
      return false;
    }

    /**
     * Returns the requirejs path for the class in which the offline handler is defined. This gives
     * DT a chance to install its own offline handler for caching data purpose.
     *
     * @returns {Promise<String>}
     */
    getOfflineHandlerUrl() {
      return Promise.resolve(null);
    }
  }

  return RuntimeEnvironment;
});

