'use strict';

// Wrap the code in a closure in order to hide it from global scope
(function main() {
  // The "oj_whenReady" global variable enables a strategy that the busy context whenReady,
  // will implicitly add a busy state, until the application calls applicationBootstrapComplete
  // on the busy state context.
  window.oj_whenReady = true;

  // only time reading from the window will be allowed - so we can specify the bootstrap individually
  // across different applications
  const VB_CONF = window.vbInitConfig || {};

  // The urlMarker is defined here and the Configuration class is used to access its value
  // This is the only place where the urlMarker is defined.
  const urlMarker = 'vp';

  const requireUtJs = 'require-ut.js';
  VB_CONF.isRequireUt = false;

  // start injected shared scripts (src/template/calculateApplicationUrl.js, src/template/getBaseUrlFromConfig.js)
  /**
   * Based on the location and vbInitConfig parameters, calculates the application URL. The underlying assumption
   * here is that the worker (for example, sw.js) is registered at the same location as index.html.
   * Depending on the existence of the marker 'vp' in the URL, the application URL can take two values:
   *   1) If the marker is not in the URL, the application URL is the current path.
   *   2) Otherwise the application URL is the part of the URL before the marker.
   *
   * This URL can be different from the base URL used to retrieve the application resource.
   * This is will be used by the router sync navigation state and URL and service worker to cache app resources
   * Note: Do not add any references to window to this function.
   * @param {*} loc Window.location or WorkerGlobalScope.location
   * @param {*} vbInitConfig application configuration
   * @param {*} urlMarker (optional) the urlMarker is defined here and the Configuration class is used to access it
   */
  const calculateApplicationUrl = (loc, vbInitConfig = {}, urlMarker = 'vp') => {
    const appName = vbInitConfig.APP_NAME;

    if (appName) {
      // Match the first occurrence of /appName/ or ending with /appName
      const regex = new RegExp(`/${appName}$|/${appName}/`, 'g');
      const result = regex.exec(loc.pathname);

      if (result) {
        let appUrl = loc.origin + loc.pathname.substring(0, result.index + 1 + appName.length);
        // Make sure to terminate with a '/'
        if (appUrl.slice(-1) !== '/') {
          appUrl += '/';
        }

        return appUrl;
      }
    }
    // Retrieve the URL without search or hash. Depending on context, pathname can be:
    // - /ic/builder/rt/rx/1.0/webApps/abc/ on the main thread
    // - /ic/builder/rt/rx/1.0/webApps/abc/sw.js in the service worker context
    // - /context.html or /debug.html for unit test
    let appUrl = loc.origin + loc.pathname;
    const visualIndex = appUrl.indexOf(`/${urlMarker}/`);

    // Make sure that we either have .../vp/... or it ends with /vp
    if (visualIndex > 0) {
      appUrl = appUrl.substring(0, visualIndex + 1);
    } else if (appUrl.substring(appUrl.length - urlMarker.length - 1) === `/${urlMarker}`) {
      appUrl = appUrl.substring(0, appUrl.length - urlMarker.length - 1);
    } else {
      // Location path might end with a resource
      // eslint-disable-next-line no-restricted-syntax
      for (const r of ['index.html', 'sw.js', 'debug.html', 'context.html']) {
        const resourceIndex = appUrl.indexOf(r);
        if (resourceIndex > 0) {
          appUrl = appUrl.substring(0, resourceIndex);
          break;
        }
      }
    }

    // Make sure to terminate with a '/'
    if (!appUrl.endsWith('/')) {
      appUrl += '/';
    }
    return appUrl;
  };
  /**
   * The base URL is where all the application resources are loaded from.
   * Note: Do not add any references to window to this function.
   * @param {*} loc Window.location or WorkerGlobalScope.location
   * @param {*} vbInitConfig application configuration
   * @param {*} applicationUrl calculated application URL
   * @return {String}  the baseUrl
   */
  const getBaseUrlFromConfig = (loc, vbInitConfig, applicationUrl) => {
    // If BASE_URL is defined in vbInitConfig, we use it as-is.
    let baseUrl = vbInitConfig.BASE_URL;
    // If not defined, the base URL is a combination of application URL and BASE_URL_TOKEN
    if (!baseUrl) {
      baseUrl = applicationUrl;
      const baseUrlToken = vbInitConfig.BASE_URL_TOKEN;
      if (baseUrlToken) {
        // When using vanity URL, BASE_URL_TOKEN starts with '/', which is relative to the domain.
        if (baseUrlToken[0] === '/') {
          // Note: prepend the origin due to existing apps relying on the full absolute url
          baseUrl = `${loc.origin}${baseUrlToken}`;
        } else {
          baseUrl = `${baseUrl}${baseUrlToken}`;
        }
      }
    }
    // Make sure to terminate with a '/', because BASE_URL or BASE_URL_TOKEN might not be
    if (!baseUrl.endsWith('/')) {
      baseUrl += '/';
    }
    return baseUrl;
  };

  // end injected shared scripts
  const configuration = {
    // Hold a copy of this function for testing
    calculateApplicationUrl,

    appName: VB_CONF.APP_NAME,

    // Store the URL marker
    // The urlMarker is defined here and the Configuration class is used to access its value
    urlMarker,

    // Calculate the value once
    applicationUrl: calculateApplicationUrl(window.location, VB_CONF),

    getBaseUrlFromConfig,
  };

  /**
   * Detect if we have the chrome debugger extension installed.
   */
  function debuggerHandShake() {
    // only install the listener for chrome
    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
      window.addEventListener('message', (event) => {
        const data = event.data;
        const type = data.type;

        if (type === 'vbDebuggerHandShake') {
          // If we get a hand shake message, invoke handShake method on the debugger to make
          // sure it's installed. Also import versions to initialize the version data since it needs to
          // communicated to the debugger during hand shake.
          requirejs(['vb/private/debug/applicationDebugStream', 'vb/versions'], (ApplicationDebugStream) => {
            ApplicationDebugStream.handShake();
          });
        } else if (type === 'vbDebuggerUninstalled') {
          console.log('Debugger extension uninstalled');
          VB_CONF.debuggerInstalled = false;
        }
      }, false);
    }
  }

  function installServiceWorker(Utils, config, Configuration, root) {
    // skip install service worker if skipInstall is set to true
    if (VB_CONF.SERVICE_WORKER_CONFIG && VB_CONF.SERVICE_WORKER_CONFIG.skipInstall) {
      return Promise.resolve();
    }
    return Utils.getResource('vb/private/serviceWorker/serviceWorkerManagerFactory')
      .then((serviceWorkerManagerFactory) => serviceWorkerManagerFactory.createServiceWorkerManager(VB_CONF))
      .then((swm) => {
        const swmInstance = swm.getInstance();
        return swmInstance.install(root, config, Configuration);
      });
  }

  /**
   * Require the library to communicate with DT when an application runs inside DT.
   * This needs to be done before other RT libraries in order for page preview to work in DT
   * @param  {function} initRt a callback to invoke Runtime initialization
   */
  function initRtDtEnv(initRt) {
    // Require the library to communicate with DT when an application runs inside DT.
    // This needs to be done before other library for page preview to work
    requirejs(['vb/private/rtEnvConfig'], (RtEnvConfig) => {
      const rtModule = RtEnvConfig && new RtEnvConfig().module;
      if (rtModule) {
        // This is when running in DT environment
        requirejs([rtModule], initRt);
      } else {
        initRt();
      }
    });
  }

  // Look up the root location from where this script is loaded which is used to construct the url for
  // loading the vbcs-icon-font.css.
  const scriptSrc = document && document.currentScript.src;
  const scriptIndex = scriptSrc.indexOf('visual-runtime');
  const isBundled = scriptIndex !== -1;
  const scriptRoot = scriptSrc.substring(0, scriptIndex);

  // Check whether require-ut is used or not and update the flag in window.vbInitConfig
  Array.from(document.scripts).forEach((script) => {
    if (script.src.includes(requireUtJs)) {
      VB_CONF.isRequireUt = true;
    }
  });

  function init(root = '') {
    // detect chrome debugger extension
    debuggerHandShake();

    // Init RtDt environment first then continue with RT initialization
    initRtDtEnv(() => {
      if (navigator.webdriver && isBundled) {
        // for security reason, we'll only enable loading of vb-test-utils.js if the application has
        // vbInitConfig.TEST_MODE set to webdriver
        requirejs.config({
          paths: {
            vbtu: `${root}vb-test-utils`,
            // needed for webdriver test to access chai in release mode grunt test-ui-release
            chai: `${root}vb-test-utils`,
          },
          bundles: {
            vbtu: ['vbtu/testUtils'],
          },
        });
      }

      requirejs([
        'vb/private/constants',
        'vb/private/utils',
        'vb/private/configuration',
        'vb/private/configLoader'],
      (Constants, Utils, Configuration, ConfigLoader) => {
        Configuration.init(configuration);

        if (VB_CONF.TEST_MODE === Constants.TestMode.ACTION_CHAIN) {
          // when running in action chain test mode, skip bootstrapping the application
          console.log('Starting VB in action chain test mode.');

          // send a message to the action chain tester to start running the test
          window.postMessage({
            type: 'vbActionChainTesterHandShake',
          }, window.location.origin);
        } else {
          // install service worker, then load application configuration
          ConfigLoader.init()
            .then((config) => installServiceWorker(Utils, config, Configuration, root)
              .then(() => Utils.getResource('vb/bootstrap'))
              .then((bootstrap) => bootstrap()))
            .then(() => {
              if (VB_CONF.TEST_MODE === Constants.TestMode.UNIT_TEST) {
                // when running in unit test mode, we need to notify karma to start the tests after bootstrap
                console.log('Starting VB in unit test mode.');

                // send a message to karma to start running tests
                window.postMessage({
                  type: 'vbUnitTestHandShake',
                }, window.location.origin);
              }
            });
        }
      });
    });
  }

  function requirejsConfigAndInit() {
    const requireConfig = {};
    // Since we set require baseUrl, this value can later be retrieved by calling requirejs.toUrl('')
    // Don't set the baseUrl when running in action chain or unit test mode since the baseUrl should be
    // set by the test runner.
    if (VB_CONF.TEST_MODE !== 'actionChain' && VB_CONF.TEST_MODE !== 'unitTest') {
      requireConfig.baseUrl = configuration.getBaseUrlFromConfig(window.location, VB_CONF, configuration.applicationUrl);
    }

    // It is possible that window.vbInitConfig is not defined and that requirejs has
    // already been configured, in that case, skip the configuration
    if (Object.keys(VB_CONF).length > 0) {
      // Call requirejs.config once with the complete configuration
      requirejs.config(requireConfig);
    }

    const libDir = undefined; // injected by replace.js Grunt task
    const srcDir = undefined; // injected by replace.js Grunt task

    requirejs(['vb/private/vbRequireConfig'], (VbRequireConfig) => {
      VbRequireConfig.config(libDir, srcDir);
      init(scriptRoot);
    });
  }

  /**
   * Return true if we are in the hidden iframe for refreshing the access token.
   *
   * @returns {boolean}
   */
  function inRefreshAuthTokenIframe() {
    try {
      return window.self !== window.top && window.frameElement.id === 'vb-refresh-access-token-iframe';
    } catch (e) {
      return false;
    }
  }

  /**
   * Post the access token which should be attached to the URL as a hash back to the parent window.
   */
  function refreshAuthToken() {
    window.parent.postMessage({
      method: 'vbRefreshAuthToken',
      args: [window.location.hash],
    }, window.location.origin);
  }

  if (!inRefreshAuthTokenIframe()) {
    requirejsConfigAndInit();
  } else {
    refreshAuthToken();
  }
}());

define("generated/vb/main", function(){});

