'use strict';

define('vb/private/vx/v2/remoteExtensionAdapter',[
  'vb/private/vx/v2/endpointExtensionAdapter',
  'vb/private/utils',
], (EndpointExtensionAdapter, Utils) => {
  /**
   * The extension adapter used to load extension from the extension manager
   */
  class RemoteExtensionAdapter extends EndpointExtensionAdapter {
    /**
     * Gets the extension version
     *
     * @return {String}  The extension version
     */
    getExtensionVersion() {
      const version = super.getExtensionVersion();

      if (!version) {
        // For v2 adds the digest version if defined. Default to 'latest'
        const digestVersion = Utils.cleanString(
          this.vbInitConfig && this.vbInitConfig.REGISTRY_DIGEST_VERSION,
        );
        return digestVersion ? encodeURIComponent(digestVersion) : 'latest';
      }
      return version;
    }
  }

  return RemoteExtensionAdapter;
});

