'use strict';

// we name this module as an exception so it can be pre-loaded and then referenced by main.js
define('vb/private/vbRequireConfig', [], () => {
  /**
   *
   * @param {string} libDir root location of the libraries, if unspecified library paths will not be included
   * @param {string} srcDir root location of the VB modules, if unspecified VB paths will not be included
   *
   * @returns RequireJs configuration object
   */
  const getConfig = (libDir, srcDir) => {
    const libPaths = libDir === undefined ? {} : {
      /* Jet and Jet Dependencies */
      jquery: `${libDir}/jquery/dist/jquery.min`,
      'jqueryui-amd': `${libDir}/@oracle/oraclejet/dist/js/libs/jquery/jqueryui-amd-1.13.2`,
      ojs: `${libDir}/@oracle/oraclejet/dist/js/libs/oj/debug`,
      ojdnd: `${libDir}/@oracle/oraclejet/dist/js/libs/dnd-polyfill/dnd-polyfill-1.0.2`,
      ojL10n: `${libDir}/@oracle/oraclejet/dist/js/libs/oj/ojL10n`,
      ojtranslations: `${libDir}/@oracle/oraclejet/dist/js/libs/oj/resources`,
      '@oracle/oraclejet-preact': `${libDir}/@oracle/oraclejet-preact/amd`,
      hammerjs: `${libDir}/hammerjs/hammer`,
      /* touchr is needed for JET 6.0.0 */
      touchr: `${libDir}/@oracle/oraclejet/dist/js/libs/touchr/touchr`,
      /* preact is needed for JET 11.0.0 */
      preact: `${libDir}/preact/dist/preact.umd`,
      /* preact/compat is needed for JET 12.0.0 */
      'preact/compat': `${libDir}/preact/compat/dist/compat.umd`,
      'preact/hooks': `${libDir}/preact/hooks/dist/hooks.umd`,
      'preact/jsx-runtime': `${libDir}/preact/jsx-runtime/dist/jsxRuntime.umd`,
      /* VB dependencies */
      knockout: `${libDir}/knockout/build/output/knockout-latest`,
      signals: `${libDir}/signals/dist/signals.min`,
      css: `${libDir}/require-css/css`,
      text: `${libDir}/requirejs-text/text`,
      pouchdb: `${libDir}/@oracle/oraclejet/dist/js/libs/persist/debug/pouchdb-browser-7.2.2`,
      persist: `${libDir}/@oracle/oraclejet/dist/js/libs/persist/debug`,
      'web-vitals': `${libDir}/web-vitals/dist/web-vitals.umd`,

      /* for webdriver tests */
      chai: `${libDir}/chai/chai`,
    };

    const srcPaths = srcDir === undefined ? {} : {
      /* VB common */
      vbc: `${srcDir}/vbc`,

      /* VB runtime */
      vb: `${srcDir}/vb`,

      /* VB service worker */
      vbsw: `${srcDir}/vbsw/vbServiceWorkerModules`,

      sw: `${srcDir}/sw`,

      vbsu: `${srcDir}/vbsu`,

      /* for webdriver tests */
      vbtu: `${srcDir}/vbtu`,
    };

    const config = {
      paths: Object.assign({}, libPaths, srcPaths),
      map: {
        '*': {
          vbRuntimeEnvironmentClass: 'vb/private/helpers/runtimeEnvironment',
          /* built in types */
          'vb/RestHookHandler': 'vb/helpers/restHookHandler',
          'vb/ServiceDataProvider': 'vb/private/types/serviceDataProvider',
          'vb/ServiceDataProvider2': 'vb/private/types/dataProviders/serviceDataProvider2',
          'vb/MultiServiceDataProvider': 'vb/private/types/multiServiceDataProvider',
          'vb/MultiServiceDataProvider2': 'vb/private/types/dataProviders/multiServiceDataProvider2',
          'vb/ArrayDataProvider': 'vb/private/types/arrayDataProvider',
          'vb/ArrayDataProvider2': 'vb/private/types/arrayDataProvider2',
          'vb/MetadataDescriptor': 'vb/private/types/metadataDescriptor',
          // todo: remove ServiceRampTransforms when DT has caught up
          'vb/ServiceRampTransforms': 'vb/private/services/ramp/rampTransforms',
          'vb/BusinessObjectsTransforms': 'vb/private/services/ramp/rampTransforms',
          'vb/BusinessObjectServiceTransforms': 'vb/private/services/boss/bossTransforms',
          'vb/DefaultSecurityProvider': 'vb/private/types/defaultSecurityProvider',

          // alias public Rest helper class to the private location
          'vb/helpers/rest': 'vb/private/helpers/rest',

          // For backward compatibility
          'vb/UserSecurityProvider': 'vb/private/types/defaultSecurityProvider',
          'vb/extensions/process/ProcessDataProvider': 'vb/extensions/process/private/types/processDataProvider',

          // dynamic UI factory bridges
          'vb/FactoryMetadataProviderDescriptor': 'vb/private/types/factoryMetadataProviderDescriptor',
          'vb/extensions/dynamic/ServiceMetadataProviderFactory':
            'vb/extensions/dynamic/private/factories/serviceMetadataProviderFactoryBridge',
          'vb/extensions/dynamic/JsonMetadataProviderFactory':
            'vb/extensions/dynamic/private/factories/jsonMetadataProviderFactoryBridge',
          'vb/extensions/dynamic/DynamicLayoutMetadataProviderFactory':
            'vb/extensions/dynamic/private/factories/dynamicLayoutMetadataProviderFactoryBridge',
          // dynamic UI "metadata" variable types
          'vb/JsonMetadataProviderDescriptor':
            'vb/extensions/dynamic/private/types/jsonMetadataProviderDescriptor',
          'vb/ServiceMetadataProviderDescriptor':
            'vb/extensions/dynamic/private/types/serviceMetadataProviderDescriptor',
          'vb/ContainerMetadataProviderDescriptor':
            'vb/extensions/dynamic/private/types/containerMetadataProviderDescriptor',
          'vb/DynamicLayoutMetadataProviderDescriptor':
            'vb/extensions/dynamic/private/types/dynamicLayoutMetadataProviderDescriptor',
          'vb/HeterogeneousMetadataProviderDescriptor':
            'vb/extensions/dynamic/private/types/heterogeneousMetadataProviderDescriptor',

          // for backwards compatibility with the opt prefix
          opt: 'persist',

          // mapping for versions.js & versions.json
          // vbVersions: '/package',,

          main: 'vb/main',
        },
      },

      shim: {
        jquery: libDir === undefined ? {} : {
          exports: ['jQuery', '$'],
        },
      },
      config: {
        text: {
          // allow cross-domain requests
          // remote server allows CORS
          useXhr: () => true,
        },
        // Give an initial empty ojL10n configuration.
        // This gets around a problem with requirejs & the ojL10n plugin where subsequent changes to
        // the ojL10n configuration aren't received.
        // see VBS-15807: Remote Translations merge is not working for App UI
        // and VBS-31446: Locale changing broken with VB 2401.0.0-alpha2.0
        ojL10n: {},
      },
    };
    return config;
  };

  /**
   * Sets up paths and map for VBRT. It avoids overwriting any existing paths.
   *
   * @param {string} libDir root location of the libraries, if unspecified library paths will not be configured
   * @param {string} srcDir root location of the VB modules, if unspecified VB paths will not be configured
   */
  const config = (libDir, srcDir) => {
    const rjsConfig = getConfig(libDir, srcDir);

    let currentPaths = {};
    try {
      currentPaths = requirejs.s.contexts._.config.paths || {};
    } catch (err) {
      currentPaths = {};
    }

    Object.keys(rjsConfig.paths).forEach((path) => {
      if (currentPaths[path]) {
        delete rjsConfig.paths[path];
      }
    });

    requirejs.config(rjsConfig);
  };

  return { config, getConfig };
});

