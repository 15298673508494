'use strict';

define('vb/private/services/operationRefEndpointProvider',[
  'vb/private/log',
  'vb/private/configLoader',
  'vb/private/services/operationRefEndpoint',
  'vb/private/services/serviceResolver',
  'vb/private/services/services',
  'vb/private/constants',
], (Log, ConfigLoader, OperationRefEndpoint, ServiceResolver, Services, Constants) => {
  //
  const logger = Log.getLogger('/vb/private/services/operationRefEndpointProvider');

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions:
  // string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  // We do not want to escape ".", "*", "{" and "}" as we need them
  const REG_EXP_ESCAPE = /[+?^$()|[\]\\]/g;
  const escapeRegExp = (string) => string.replace(REG_EXP_ESCAPE, '\\$&');

  // if it has "{}" then replace the param name within it with ".*" expression and try to match the RegExp
  const regexTemplates = /{(.*?)}/gi;

  class OperationRefEndpointProvider {
    static getOperationRefUrl(operationRef) {
      return operationRef ? operationRef.split('#')[0] || '' : '';
    }

    // typical mapping object
    // {
    //   "urlToFetch": "http://localhost:1988/webApps/ifixitfaster/api/incidents?technician=hcr",
    //   "url": "http://localhost:1988/webApps/ifixitfaster/api",
    //   "id": "incidents",
    //   "nameForProxy": "incidents",
    //   "headers": {
    //   "vb-allow-anonymous-access": true
    // },
    //   "baseUrl": "",
    //   "serviceName": "incidents"
    // }"

    /**
     * This is called by AbstractRestHelper._getEndpoint()
     *
     * @param {{url: string, operationRef: string, serviceName: string}} endpointReference
     * @param {Container|ServiceContext} [serviceContext] - typically the container which defines the scope in which
     *                                                      the endpoint is being invoked.
     * @returns {Promise<OperationRefEndpoint>}
     */
    static getEndpoint(endpointReference, serviceContext /* , serverVariables */) {
      return OperationRefEndpointProvider.getServiceName(endpointReference, serviceContext)
        .then((serviceName) => {
          if (!serviceName) {
            // return one for the raw URL
            logger.info('Unable to map URL, using Endpoint with no Service', endpointReference.url);
          }
          return new OperationRefEndpoint(serviceName, endpointReference.url,
            endpointReference.operationRef, serviceContext).init();
        });
    }

    /**
     *
     * @param {{url: string, operationRef: string, serviceName: string}} endpointReference
     * @param {Container|ServiceContext} [serviceContext] - typically the container which defines the scope in which
     *                                                      the endpoint is being invoked.
     * @returns {Promise<string>}
     * @private
     */
    static async getServiceName(endpointReference, serviceContext) {
      // in case we were given EndpointReference instance with already resolved service, use that name
      if (endpointReference.serviceName) {
        return endpointReference.serviceName;
      }

      const url = endpointReference.url;
      if (url) {
        const refNamespace = ServiceResolver.getNamespace(serviceContext);
        const matched = await OperationRefEndpointProvider.findMatchInMetadata(url, refNamespace);

        if (matched) {
          return matched.serviceName;
        }
      }

      // fall back to the old behavior
      return OperationRefEndpointProvider.getMapping(endpointReference)
        .then((mapping) => mapping && mapping.serviceName);
    }

    /**
     * Finds a "url mapping hint" object defined in the catalog metadata that is matching given URL.
     *
     * @param {string} url URL to match
     * @param {string} refNamespace Namespace context in which the resolution should be done
     * @returns {Promise<object>}
     */
    static async findMatchInMetadata(url, refNamespace) {
      const metadata = await ConfigLoader.catalogRegistry.getAllMetadata(refNamespace);

      // search one namespace at a time
      const matched = Object.keys(metadata).reduce(async (currMatchPromise, ns) => {
        const currentMatch = await currMatchPromise;
        return currentMatch || OperationRefEndpointProvider.findMatch(url, ns, metadata[ns].urlMappingHints);
      },
      Promise.resolve(null));

      return matched;
    }

    /**
     * Finds a  object defined in the list of metadata objects that is matching given URL.
     * If necessary it resolves the vb-catalog:// type of URL in the given metadata before
     * matching it with the URL.
     *
     * @param {string} url URL to match
     * @param {string} namespace Namespace context in which the resolution should be done
     * @param {object[]} [metadata] Metadata objects to match. Their 'url' property is used for matching
     * @returns {Promise<object>} Promise of matched metadata object or null value
     */
    static async findMatch(url, namespace, metadata = []) {
      const needsResolution = metadata.some((map) => !map.resolvedUrl);
      // we want to avoid repeated resolution of vb-catalog:// URLs
      // we do it only once and then store the resolved value
      if (needsResolution) {
        const handler = ConfigLoader.protocolRegistry.getHandler(Constants.VbProtocols.CATALOG);
        const resolved = await handler.resolveUrls(metadata
          .map((mapping) => mapping && (mapping.resolvedUrl || mapping.url)), namespace);
        resolved.forEach((resolvedUrl, index) => {
          // eslint-disable-next-line no-param-reassign
          metadata[index].resolvedUrl = resolvedUrl;
        });
      }
      return metadata.find((hint) => (hint.resolvedUrl
        && OperationRefEndpointProvider.matches(url, hint.resolvedUrl)
        && hint.serviceName));
    }

    /**
     * Checks if the resolved URL matches given URL. THe two URLs match if the beginning of the URL
     * matches the resolved URL. Resolved URL may contain arbitrary template fragments, eg. "{version}"
     * in "vb-catalog://backends/extA:backendFoo/{version}/data".
     *
     * @param {string} url
     * @param {string} resolvedUrl
     * @returns {boolean}
     */
    static matches(url, resolvedUrl) {
      if (url.startsWith(resolvedUrl)) {
        return true;
      }

      // if it has "{}" then replace the param name within it with ".*" expression and try to match the RegExp
      const regExpUrl = escapeRegExp(resolvedUrl.replaceAll(regexTemplates, '.*'));
      return (new RegExp(regExpUrl)).test(url);
    }

    /**
     * try to map the url to a catalog service, or already-opened openapi3/swagger
     * @param {{url: string, operationRef: string}} endpointReference
     * @returns {Promise<*>}
     */
    static getMapping(endpointReference) {
      // first try the url
      return ConfigLoader.urlMapper.getUrlMapping(endpointReference.url)
        .then((mapping) => {
          // if we didn't get a mapping, try to get a mapping from the operationRef, open the service,
          // and then retry the url
          if (!mapping) {
            // If we didn't find a mapping for the URL, we can try to find a catalog.json mapping for the operationRef,
            // which points to openapi3.  if we can open that, we may then be able to get a mapping for the url.
            // If we don't have a catalog.json mapping for the operationRef at this point, we will not try to
            // fetch the operationRef URL (it would likely fail, since we have no mapped credentials).

            const operationRefUrl = OperationRefEndpointProvider.getOperationRefUrl(endpointReference.operationRef);

            return ConfigLoader.urlMapper.getUrlMapping(operationRefUrl)
              .then((refMapping) => {
                if (refMapping && refMapping.serviceName) {
                  const services = OperationRefEndpointProvider
                    .createServicesModel(refMapping.serviceName, operationRefUrl);

                  return services.load()
                    .then((debug) => {
                      console.log(debug);
                      return ConfigLoader.urlMapper.getUrlMapping(endpointReference.url);
                    });
                }
                return null;
              });
          }
          return mapping;
        });
    }

    /**
     * creates a Services model, for the Endpoint that represents the raw, unmapped URL.
     * @param name
     * @param operationRefUrl
     * @returns {Services}
     */
    static createServicesModel(name, operationRefUrl) {
      return new Services({
        relativePath: '',
        serviceFileMap: { [name]: operationRefUrl },
        expressionContext: {},
        protocolRegistry: ConfigLoader.protocolRegistry,
      });
    }
  }

  return OperationRefEndpointProvider;
});

