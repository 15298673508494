/* eslint-disable class-methods-use-this */

'use strict';

define('vb/private/pwa/pwaServiceWorkerManagerClass',[
  'vbc/private/log',
  'vbc/private/trace/spanContext',
  'vbc/private/trace/tracer',
  'vb/private/configLoader',
  'vb/private/pwa/swLogOptions',
  'vb/private/pwa/swTraceOptions',
  'vb/private/utils',
  'vb/private/constants',
  'vb/versions',
  'vbsw/private/serviceWorkerManagerClass',
], (
  Log,
  SpanContext,
  Tracer,
  ConfigLoader,
  SwLogOptions,
  SwTraceOptions,
  Utils,
  Constants,
  Versions,
  ServiceWorkerManagerClass,
) => {
  const logger = Log.getLogger('/vb/private/pwa/pwaServiceWorkerManager');

  /**
   * An implementation of ServiceWorkerManagerClass to support web app PWA's. This type of manager should only be
   * created when globalThis.vbInitConfig.PWA_CONFIG is specified
   *
   * @see PwaUtils.isWebPwaConfig
   */
  class PwaServiceWorkerManagerClass extends ServiceWorkerManagerClass {
    constructor() {
      super();
      this.config = {};

      // disable workbox logging unless we are in debug mode:
      // https://developers.google.com/web/tools/workbox/guides/configure-workbox#disable_logging
      // eslint-disable-next-line no-underscore-dangle
      globalThis.__WB_DISABLE_DEV_LOGS = true;

      this.messageQueue = [];
      this.loadPWAInfoPromise = null;
    }

    getServiceWorkerVersion() {
      return this.wb.messageSW({ type: 'GET_VERSION' })
        .then((version) => {
          logger.info('Version from the service worker:', JSON.stringify(version, null, 2));
          return version;
        })
        .catch((error) => {
          logger.warn('Failed to detect service worker version:', error);
          return undefined;
        });
    }

    /**
     * See https://github.com/w3c/ServiceWorker/issues/799, specifically Jake's example:
     * https://github.com/w3c/ServiceWorker/issues/799#issuecomment-165499718
     * to explain why we can't use navigator.serviceWorker.ready
     * The ready read-only property of the ServiceWorkerContainer interface provides a way of delaying code
     * execution until a service worker is active. It returns a Promise that will never reject, and which waits
     * indefinitely until the ServiceWorkerRegistration associated with the current page has an active worker.
     * Once that condition is met, it resolves with the ServiceWorkerRegistration.
     * Active service worker is not the same thing as the service worker controlling the page
     *
     * MJR ?? Only used in tests/sw/pwaServiceWorkerManagerSpec
     * Test-only function?  Perhaps update pwaServiceWorkerManagerSpec to use something similar what's used in
     * tests/puppeteer/tests/pwaSpecBase.openTestPageWithConfig()
     *
     * @param scriptUrl context root relative service worker script url, for example: sw.js
     * @returns {Promise} a promise that resolves once a service worker with a given scriptUrl is controlling the page
     */
    serviceWorkerReady(scriptUrl) {
      return new Promise((resolve) => {
        const sw = navigator.serviceWorker.controller;
        if (sw && sw.scriptURL.endsWith(scriptUrl)) {
          logger.info('Activated service worker found:', sw.scriptURL);
          resolve();
        } else {
          this.controllerChangeHandler = (e) => {
            logger.info('navigator.serviceWorker.controller change for', e.target.controller.scriptURL);
            resolve();
          };
          navigator.serviceWorker.addEventListener('controllerchange', this.controllerChangeHandler);
        }
      });
    }

    /**
     * Install the Service Worker
     * @param modulePath the path from which the service worker should its dependent modules
     * @param externalConfig config object containing external require config, plugins, and logConfig
     * @param Configuration applicationUrl and getBaseUrlFromConfig()
     * @returns {Promise}
     */
    installServiceWorkers(modulePath, externalConfig, Configuration) {
      return this.getServiceWorkerConfig(modulePath, externalConfig, Configuration)
        .then((config) => this.installServiceWorker(config))
        .then((result) => [result])
        .catch((error) => {
          logger.warn('Failed to install service worker:', error);
          return undefined;
        });
    }

    /**
     * @param {*} vbInitConfig config
     * @returns a path to a specific version of workbox on CDN. For applications with a default configuration,
     * this will be an internal CDN, for example:
     * https://static.oracle.com/cdn/vb/workbox/releases/5.1.4/
     * But if vbInitConfig.WORKBOX_CDN_PATH is specified, it will be used instead (workbox version remains dictated
     * by VB), for example:
     * https://storage.googleapis.com/workbox-cdn/releases/6.0.0-alpha.3/
     * This is useful on Android, workbox needs to be installed from an internal CDN.
     */
    getWorkboxCdnPath(vbInitConfig = globalThis.vbInitConfig) {
      return `${vbInitConfig.WORKBOX_CDN_PATH || Versions.workbox.cdnPath}${Versions.workbox.version}/`;
    }

    /**
     * Caching is enabled unless vbInitConfig.PWA_CONFIG.disableCaching is set to true.
     * In all other cases, it is enabled
     * @param {Object} vbInitConfig
     */
    isCachingEnabled(vbInitConfig = globalThis.vbInitConfig) {
      if (vbInitConfig.PWA_CONFIG.disableCaching === true) {
        return false;
      }
      return true;
    }

    /**
     * Caching is enabled unless either vbInitConfig.PWA_CONFIG.disableCaching or
     * vbInitConfig.PWA_CONFIG.disableExtensionCaching is set to true.
     * In all other cases, it is enabled
     * @param {Object} vbInitConfig
     */
    isExtensionCachingEnabled(vbInitConfig = globalThis.vbInitConfig) {
      if (!vbInitConfig.PWA_CONFIG || (vbInitConfig.PWA_CONFIG.disableCaching === true)) {
        return false;
      }
      if (vbInitConfig.PWA_CONFIG.disableExtensionCaching === true) {
        return false;
      }
      return true;
    }

    /**
     * Register service worker for PWAs.
     * Despite the name, Service Worker install event won't necessarily happen,
     * because Service Worker might be registered already
     * First, setup the emulatedServiceWorkerWrapper, and then set up the Workbox Service Worker.
     * Importantly, we are NOT waiting for Workbox to complete, because all the inherited apis of the
     * Service Worker Manager only need to have the ServiceWorkerWrapper.
     * @param {object} config SERVICE_WORKER_CONFIG section of vbInitConfig
     * @returns {Promise} ServiceWorkerWrapper
     */
    installServiceWorker(config) {
      this.config = Object.assign(this.config, config);

      // Start the Workbox Setup, but don't wait for it
      Promise.resolve().then(() => {
        if (!('serviceWorker' in navigator)) {
          // Service worker could be unsupported in the browser, or,  explicity disabled.
          // (for example, in private browsing mode on FF, or, when
          // 'Delete cookies when and site data when Firefox is closed' option is set)
          // In these cases, VB application should degrade to use EmulatedServiceWorkerWrapper
          // @ts-ignore
          logger.warn('Service worker is not supported/enabled in the browser:', navigator.userAgent);
          return null;
        }

        const scriptUrlFromConfig = globalThis.vbInitConfig.PWA_CONFIG.scriptUrl || 'sw.js';
        this.scriptUrl = `${config.applicationUrl}${scriptUrlFromConfig}`;
        logger.info('Service worker url:', this.scriptUrl);

        // applicationUrl always ends with a trailing slash. This can mean, in some cases, that the service worker
        // is registered in the scope that does not control the initial request. For example, if initial request is:
        // https://fuscdrmsmc280-fa-ext.us.oracle.com/fscmUI/starterapp, applicationUrl will be:
        // https://fuscdrmsmc280-fa-ext.us.oracle.com/fscmUI/starterapp/ and the service worker will be registered in
        // the scope that does not control the initial request. This means that navigator.serviceWorker.controller will
        // be null, and serviceWorkerReady promise will never resolve, resulting in a stuck application.
        // Service worker is not supposed to have a scope broader than its own location
        // (see https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register)
        // and exceptions require special 'Service-Worker-Allowed' response headers on the service worker script:
        // (see https://developers.google.com/web/ilt/pwa/introduction-to-service-worker#registration_and_scope)
        // Currently, VB does not support this special case and having a mismatch between server configuration and
        // expected service worker scope will result in an error
        // See slack discussion: https://corparch-core-srv.slack.com/archives/G01B7V95FU0/p1603378926081800
        if (!globalThis.location.href.startsWith(config.applicationUrl)) {
          // eslint-disable-next-line max-len
          const scopeError = new Error(`Service worker scope: ${config.applicationUrl} does not match initial request: ${globalThis.location.href}`);
          throw scopeError;
        }
        let sw;

        // When updateViaCache is set to 'imports', sw won't be updated when an imported script changes (on Chrome)
        // This is not necessary, since when imported scripts change (jet, vb, workbox), vbInitConfig injected to sw.js
        // also changes
        // See: https://developers.google.com/web/updates/2019/09/fresher-sw
        const registrationOptions = { updateViaCache: 'imports' };
        return Utils.getResource('workbox-window')
          .then((workbox) => {
            // See https://developers.google.com/web/tools/workbox/modules/workbox-window
            this.wb = new workbox.Workbox(this.scriptUrl, registrationOptions);
            // keep a reference to event handler so it can be removed
            this.workboxEventHandler = this.handleEvent.bind(this);
            this.wb.addEventListener('installed', this.workboxEventHandler);
            this.wb.addEventListener('redundant', this.workboxEventHandler);
            this.wb.addEventListener('activated', this.workboxEventHandler);
            this.wb.addEventListener('waiting', this.workboxEventHandler);
            this.wb.addEventListener('message', this.workboxEventHandler);
            return this.wb.register({ immediate: true });
          })
          .catch((error) => {
            // Failed to register service worker.  Report error and fall through to install the emulated service worker.
            logger.error('Service worker registration failed with', error);
          })
          .then((r) => {
            if (r) {
              sw = r.installing || r.waiting || r.active;
            }
            if (sw) {
              logger.info('workbox register returned', sw.state, 'service worker');
            }
            if (r && r.active) {
              // Send any pending messages, and other active setup
              this.onActive();
            }
          });
      });

      // Always emulate the service worker
      // Emulated service worker is registered with the scope of what it would be for the old service worker,
      // vbServiceWorker.js, not sw.js. So we can't use config.applicationUrl here, as this will break
      // csrfTokenHandlerPlugin, for one. See https://jira.oraclecorp.com/jira/browse/VBS-11509
      logger.info('emulating service worker functionality with scope:', config.scopes[0]);
      return this.emulateServiceWorker(config.scopes[0]);
    }

    /**
     * Remove all event listeners added to handle this service worker events
     */
    removeEventListeners() {
      if (this.wb && this.workboxEventHandler) {
        this.wb.removeEventListener('installed', this.workboxEventHandler);
        this.wb.removeEventListener('redundant', this.workboxEventHandler);
        this.wb.removeEventListener('activated', this.workboxEventHandler);
        this.wb.removeEventListener('waiting', this.workboxEventHandler);
        this.wb.removeEventListener('message', this.workboxEventHandler);
      }
      if (this.controllerChangeHandler) {
        navigator.serviceWorker.removeEventListener('controllerchange', this.controllerChangeHandler);
      }
    }

    /**
     * Configure the logger in the service worker.
     * This sets the log level and may tell the service worker to send trace messages back to this thread.
     * PWA_CONFIG.enableTrace can be used for debugging, since if Tracer.isEnabled is false the service worker
     * will still send messages back to this thread, to be logged to the console.
     * @param {Object} [logConfig]
     * @param {string} [logConfig.level]
     * @returns {Promise}
     */
    configureLogging(logConfig) {
      return Promise.resolve().then(() => {
        if (this.wb) {
          // enableTrace can be explicitly turned off or on via PWA_CONFIG (see above for debugging)
          const enableTrace = (globalThis.vbInitConfig.PWA_CONFIG.enableTrace !== undefined)
            ? !!globalThis.vbInitConfig.PWA_CONFIG.enableTrace
            : Tracer.isEnabled;
          const message = {
            type: 'SET_LOGGING_LEVEL',
            payload: {
              level: logConfig && logConfig.level,
              enableTrace,
              enableTraceLog: enableTrace && !!globalThis.vbInitConfig.PWA_CONFIG.enableTraceLog,
            },
          };

          return this.wb.messageSW(message);
        }
        return null;
      });
    }

    getApplication() {
      this.applicationPromise = this.applicationPromise || Utils.getResource('vb/private/stateManagement/application');
      return this.applicationPromise;
    }

    informUser(message) {
      logger.info('Application needs to be reloaded.', message);
      return this.getApplication()
        .then((application) => application.onNewContentAvailable({ message }))
        .catch((error) => {
          logger.error(error);
        });
    }

    /**
     * @typedef TraceMessage
     * @property {string} type ('info' | 'warn' | 'error')
     * @property {number} time the time of the log in millis
     * @property {Object} sw info about the serviceworker
     * @property {string} sw.version
     * @property {string} sw.script_url
     * @property {Array<*>} args parameters passed to the log message
     *
     * @typedef TraceSpan
     * @property {string} type ('span')
     * @property {string} name e.g. ['activate' | 'install' | 'push' | 'notificationclick']
     * @property {number} startTime the start of the span in millis
     * @property {number} endTime the end of the span in millis
     * @property {Object} sw info about the serviceworker
     * @property {string} sw.version
     * @property {string} sw.script_url
     * @property {string} [sw.etc] additional trace context
     * @property {Error} [error] if an error occurred
     * @property {TraceInfo[]} [traceLog] nested TraceMessages
     *
     * @typedef {(TraceSpan & TraceMessage)} TraceInfo
     *
     * @typedef {Object} Trace
     * @method {Object} span
     * @param {Object} spanContext
     * @method finishSpan
     * @param {Object} span
     * @param {Object} spanContext
     */

    /**
     * Send a tracing span to the tracer.
     * @param {Trace} tracer
     * @param {TraceSpan} traceSpan The event trace information from the service worker
     * @param {*} parentSpan Outer Trace span
     */
    tracerSpan(tracer, traceSpan, parentSpan) {
      const traceOptions = new SwTraceOptions(traceSpan, parentSpan);

      const spanContext = new SpanContext().addOperationName(traceOptions.operationName);
      spanContext.addStartSpan(() => (Object.assign({
        msg: traceOptions.getStartMessage(),
        tags: traceOptions.getTags(),
      }, traceOptions.getStartFields())));

      spanContext.addSpanFunction((span) => {
        this.tracerLog(tracer, traceSpan.traceLog || [], span);

        spanContext.addEndSpan(() => (Object.assign({
          msg: traceOptions.getEndMessage(),
        }, traceOptions.getEndFields())));

        const error = traceOptions.getError();
        if (error) {
          spanContext.addError(error);
        }

        tracer.finishSpan(span, spanContext);
      });

      return tracer.span(spanContext);
    }

    /**
     * Send a log message to the tracer.
     * @param {Trace} tracer
     * @param {TraceMessage} traceMessage The log message from the the service worker
     * @param {*} parentSpan Outer Trace span
     */
    tracerMessage(tracer, traceMessage, parentSpan) {
      // Create new span for each log message
      const logOptions = new SwLogOptions(traceMessage, parentSpan);

      const spanContext = new SpanContext().addOperationName(logOptions.operationName);
      spanContext.addStartSpan(() => (Object.assign({
        msg: logOptions.getStartMessage(),
        tags: logOptions.getTags(),
      }, logOptions.getStartFields())));

      spanContext.addSpanFunction((span) => {
        spanContext.addEndSpan(() => (Object.assign({
          msg: logOptions.getEndMessage(),
        }, logOptions.getEndFields())));

        // Do we really need another message for the end span of a simple log message?
        // Or could we just do Tracer.startSpan(spanContext); and leave it at that?
        tracer.finishSpan(span, spanContext);
      });

      tracer.span(spanContext);
    }

    /**
     * Send each entry in the tracelog to the tracer
     *
     * @param {Trace} tracer
     * @param {TraceInfo[]} traceLog
     * @param {*} parentSpan Outer Trace span
     */
    tracerLog(tracer, traceLog, parentSpan = null) {
      traceLog.forEach((traceInfo) => {
        if (traceInfo.type === 'span') {
          this.tracerSpan(tracer, traceInfo, parentSpan);
        } else {
          this.tracerMessage(tracer, traceInfo, parentSpan);
        }
      });
    }

    reportTraceLog(tracelog) {
      /* @type Trace */
      let tracer;
      // If tracing is enabled, send tracelog to the Tracer
      if (Tracer.isEnabled) {
        // Report TraceLog to the global Tracer
        tracer = {
          span: (spanContext) => Tracer.span(spanContext),
          finishSpan: (span, spanContext) => Tracer.finishSpan(span, spanContext),
        };
      } else {
        // Otherwise, mimic Tracer api to output to the console
        // (This is a debugging / testing only aid.)
        tracer = {
          span: (spanContext) => {
            const spanOptions = spanContext.startSpanOptions();

            const info = {
              event: spanContext.operationName,
              start_time: new Date(spanOptions.startTime).toISOString(),
            };
            if (spanOptions.tags) {
              Object.assign(info, spanOptions.tags);
            }

            const type = (spanContext.operationName === 'swLog') ? spanOptions.level.toUpperCase() : 'TRACE';
            const messagePrefix = `[VB ${type} ${spanContext.operationName}]`;

            if (spanContext.operationName === 'swLog') {
              console.log(`${messagePrefix} ${spanOptions.msg}\n`, info);
            } else {
              console.group(`${messagePrefix} ${spanOptions.msg}\n`, info);
            }

            // Call the function with the start span info and message prefix
            spanContext.spanFunction()({ info, messagePrefix });
          },
          finishSpan: (span, spanContext) => {
            const spanOptions = spanContext.endSpanOptions();

            const info = Object.assign({
              end_time: new Date(spanOptions.endTime).toISOString(),
              error: spanOptions.error || false,
            }, span.info);

            if (spanContext.operationName !== 'swLog') {
              console.groupEnd();
            }
            console.log(`${span.messagePrefix} ${spanOptions.msg}\n`, info);
          },
        };
      }

      this.tracerLog(tracer, tracelog);
    }

    handleEvent(event) {
      const state = event.type;
      logger.info('Event handler invoked for service worker \'', state, '\' event');
      switch (state) {
        case 'installed':
          logger.info('Service worker installed', event.isUpdate ? 'after update' : 'for the first time');
          break;
        case 'activated':
        {
          if (event.isUpdate) {
            logger.info('Service worker activated after update');
          }
          if (event.isExternal) {
            this.informUser('New version of the service worker has been activated');
          }

          // Send any pending messages, and other active setup
          this.onActive();
          break;
        }
        case 'redundant':
        {
          logger.info('Service worker is now redundant');
          break;
        }
        case 'waiting':
        {
          logger.info('A new service worker,', event.sw.scriptURL,
            'has installed, but it cannot activate until all tabs running current version have fully unloaded.');
          // debugger;
          logger.warn(event.sw);
          this.wb.messageSkipWaiting();
          break;
        }
        case 'message':
        {
          if ((event.data.message === 'tracelog') && event.data.traceLog) {
            this.reportTraceLog(event.data.traceLog);
          }
          break;
        }
        default:
          logger.warn('Event:', state, 'was not handled');
      }
    }

    onActive() {
      // Set up logging (and tracing)
      this.configureLogging(this.config.logConfig)
        // Send message to load and setup the extension registry digest
        .then(() => this.loadPWAInfo())
        // Send any pending messages (register extensions)
        .then(() => {
          if (this.messageQueue) {
            const messageQueue = this.messageQueue;
            delete this.messageQueue;

            messageQueue.forEach((message) => {
              this.wb.messageSW(message);
            });
          }
        });
    }

    /**
     * Load the pwa-info from the extension digest and send it to the service worker to initialize the
     * extension registry digest.
     * This will create (and resolve) a promise whether or not there's a registry.
     * @returns {Promise}
     */
    loadPWAInfo() {
      this.loadPWAInfoPromise = this.loadPWAInfoPromise || Promise.resolve()
        .then(() => {
          const digestLoader = this.isExtensionCachingEnabled()
            && this.config.extensionConfig && this.config.extensionConfig.digestLoader;
          return digestLoader && digestLoader.loadDigest([Constants.Digest.PWA_INFO]);
        })
        .then((response) => {
          // No digest?  Don't bother sending to the service worker.
          if (!response) {
            return undefined;
          }

          // Evaluate JS expression and possible usage of $initParams in the extension registry digest
          // and send it to the service worker.
          // Might be null.  Send it anyway to indicate to the service work
          const digest = ConfigLoader.getEvaluatedSafe(response);
          const processExtensionDigestMessage = {
            type: 'PROCESS_EXTENSION_DIGEST',
            payload: {
              digest,
            },
          };
          return this.wb.messageSW(processExtensionDigestMessage);
        })
        .catch((error) => {
          logger.error('PWA Extension Digest Load failed', error);
        });

      return this.loadPWAInfoPromise;
    }

    /**
     * Tell the service worker to initialize an extension's cache.
     * Out of date caches for this extension are deleted.
     * The route for the extension's cache is based on the extension's baseUrl.
     * @param {string} id
     * @param {string} version
     * @param {string} baseUrl
     * @param {Array<string>} resources
     * @returns {Promise}
     */
    setupExtensionCache(id, version, baseUrl, resources) {
      const message = {
        type: 'SETUP_EXTENSION_CACHE',
        payload: {
          id,
          version,
          baseUrl,
          resources,
        },
      };

      // If we have a message queue,
      // put the message on the queue so it can be sent when the service worker is activated
      if (this.messageQueue) {
        this.messageQueue.push(message);
        return Promise.resolve();
      }

      return this.wb.messageSW(message);
    }

    /**
     * Determine if the Service worker is in the process of caching extensions.
     * @returns {Promise<string>} 'IDLE' | 'CACHING' | 'WAITING'
     */
    getExtensionCachingStatus() {
      if (this.messageQueue && this.messageQueue.length) {
        // Have some to send (currently the only things in messageQueue are SETUP_EXTENSION_CACHE messages)
        return Promise.resolve('CACHING');
      }

      const message = {
        type: 'EXTENSION_CACHING_STATUS',
        payload: {},
      };
      return this.wb.messageSW(message);
    }
  }

  return PwaServiceWorkerManagerClass;
});

