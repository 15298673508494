'use strict';

define('vb/extensions/dynamic/private/helpers/serviceMetadataProviderHelper',[
  'vb/private/utils',
  'vb/private/log',
  'vb/private/services/endpointReference',
  'vb/private/services/servicesManager',
  'vb/extensions/dynamic/private/helpers/dataDescriptionMetadataProviderHelper',
  'vb/private/constants',
  'vb/extensions/dynamic/private/models/layoutResource',
  'vb/extensions/dynamic/private/models/serviceMetadata',
],
(Utils,
  Log,
  EndpointReference,
  ServicesManager,
  DataDescriptionMetadataProviderHelper, Constants, LayoutResource, ServiceMetadata) => {
  const logger = Log.getLogger('/vb/extensions/dynamic/private/helpers/serviceMetadataProviderHelper');
  /**
   * DataDescriptionMetadataProviderHelper can be used as the "vbHelper" interface that is passed to
   * JET dynamic UI metadata provider for interfacing with VB.
   *
   * This extends ConfigurableMetadataProviderHelper, and implements the openapi3-based version of
   * the helper; the metadata comes from VB service metadata, rather than a local JSON file.
   *
   * This contains one additional function, loadExternalServiceMetadata, which is used by JET, but is deprecated.
   *
   * The very early dynamic UI /VB integration required some workarounds to enable its use; that functionality
   * is all encapsualted in the class, and should eventually be deprecated/removed.
   *
   * @see DataDescriptorMetadataProviderHelper for the closely-related JSON-based version.
   *
   * its 'private', but shared with JET; not for use my application developers
   */
  class ServiceMetadataProviderHelper extends DataDescriptionMetadataProviderHelper {
    /**
     *
     * @param options {object} for the helper and provider.
     * @param options.endpoint {string} required VB endpoint ID
     * @param vbAppContext the context created by VB, and passed as 'options.context' to the components/providers
     * @param container the VB container where the dynamic component with this layout will be used
     * @returns {ServiceMetadataProviderHelper}
     */
    static get(options, vbAppContext, container) {
      const endpointId = options.endpoint;
      // get the base configuration
      const helperOptions = ServiceMetadataProviderHelper.getHelperConfiguration();

      // the 'metadata' is unused, because we override the 'fetch' method.
      delete helperOptions.descriptors.metadata;

      // add the endpointId to the existing options
      const opts = Object.assign({
        endpointId,
      }, options, { helper: helperOptions });

      return new ServiceMetadataProviderHelper(opts, vbAppContext, container).init();
    }

    /**
     *
     * @returns {object}
     */
    static getHelperConfiguration() {
      // get the base configuration
      const helperOptions = DataDescriptionMetadataProviderHelper.getHelperConfiguration();

      // remove data-description resource descriptor so we can replace it with data-description-overlay
      helperOptions.descriptors = helperOptions.descriptors
        .map((desc) => {
          if (desc.resourceName === 'data-description') {
            return {
              resourceName: 'data-description-overlay',
              altExtensionResourceName: 'data-description',
              propertyMap: {
                descriptor: 'clientMetadata',
                viewModel: 'clientMetadataModel',
              },
              modelClass: LayoutResource,
              skipTemplate: true,
            };
          }

          return desc;
        });

      return helperOptions;
    }

    /**
     *
     * @param options {object}
     * @param options.endpointId {string}
     * @param vbAppContext
     * @param container
     */
    constructor(options, vbAppContext, container) {
      super(options, vbAppContext, container);

      this.endpointId = options.endpointId || ''; // empty string should never happen
      this.endpointReference = new EndpointReference(options.endpointId, container);

      // If the endpointReference has a namespace, it means that we need to load the layout from
      // the extension identified by that namespace as well. If namespace is base, we use the
      // application container to load the layout artifacts. We use the base container
      // if the extension id of the base container matches namespace. Otherwise, we throw
      // an error for now until we implement a way to look up a page container given an
      // extension id.
      const namespace = this.endpointReference.namespace;
      if (namespace) {
        this.extensionId = namespace;
      }
    }

    /**
     * Overridden to return true if the endpoint is a BOSS endpoint.
     *
     * @returns {boolean}
     */
    get isRunnableOnServer() {
      // Only a BOSS endpoint will have a module name.
      return !!this.endpointReference.moduleName;
    }

    /**
     * Used by JET to get an unique url for caching metadata. To guarantee the url is unique,
     * it will be constructed based on the extension id, the container's full path and
     * the id of the metadata descriptor variable.
     *
     * @returns {Promise<String>}
     */
    toUrl() {
      return Promise.resolve()
        .then(() => `${this.extension.id}/${this.container.fullPath}/${this.id}`);
    }

    /**
     * Return the metadata in JSON format.
     *
     * Note: This method replaces the deprecated fetch method and is more efficient because it does not
     * stringify the service definition and wrap it in a Response object.
     *
     * @see ConfigurableMetadataProviderHelper.getMetadata
     * @override
     */
    getMetadata() {
      return this.fetchDirect(true)
        .then((metadata) => {
          // descriptor used to process imports in the info.x-vb section of the service metadata
          const descriptors = [{
            resourceName: '',
            propertyMap: {
              descriptor: 'data',
              viewModel: 'dataModel',
            },
            modelClass: ServiceMetadata,
            metadata,

            // only need to load imports so skip everything else
            skipTemplate: true,
            skipFunctions: true,
            skipBundles: true,
            skipExtensions: true,
          }];

          // use ui/self as the resource path for V2 extension
          const resourcePath = (this.extension.id !== Constants.ExtensionFolders.BASE)
            ? `${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/`
            : '';

          return this.loadLayoutResources(resourcePath, descriptors);
        });
    }

    /**
     * Returns details describing the endpoint reference.
     * Returned object has this properties:
     *  serviceDef - JSON Object containing service definition model that includes referenced endpoint
     *  requestInit - configuration for the fetch() Request object used to get the definition
     * @returns {Promise<{serviceDef, catalogInfo, requestInit}>}
     * @private
     */
    fetchServiceInfo() {
      if (!this.fetchServiceInfoPromise) {
        // Get all necessary information about the referenced endpoint
        this.fetchServiceInfoPromise = ServicesManager.getDefinitionInfo(this.endpointReference);
      }
      return this.fetchServiceInfoPromise;
    }

    /**
     * 'direct' means, return the service definition already referenced by the application.
     * The service is loaded using the normal service definition loading mechanisms, instead of Rest helper fetch().
     * @param {Boolean} returnJson If true, return the service definition in JSON format. Otherwise, return
     * the service definition via a Response object.
     * @returns {Promise.<Response|Object>}
     * @private
     */
    fetchDirect(returnJson) {
      return this.fetchServiceInfo()
        .then((info) => {
          if (info && info.serviceDef) {
            const { serviceDef } = info;

            if (returnJson) {
              return serviceDef;
            }

            const s = JSON.stringify(serviceDef);
            return new Response(s);
          }

          throw new Error(`unable to load service definition: ${this.endpointReference.serviceId}`);
        });
    }

    /**
     * Allows override of the default '../../dynamicLayouts/<service>/<path>/layout.json' layout path
     * This also overrides the layoutRoot.
     * @param path
     * @returns {ServiceMetadataProviderHelper}
     */
    setLayoutPath(path) {
      this.layoutPathOverride = path;
      return this;
    }

    /**
     * Get the path for the given operationId, remove all the parameters, and use that as the path.
     * examples: /foo/{foo_id}/bar/{bar_id} => foo/bar
     *
     * @override
     */
    calcLayoutPath() {
      if (!this.layoutPrefixPromise) {
        if (this.layoutPathOverride) {
          // getLayoutPrefix used to be getLayoutPath; the override isn't ever used, but just in case it is,
          // interpret it as a path prefix, and strip the layout.json
          let prefixOverride = this.layoutPathOverride;
          const index = prefixOverride.indexOf('layout.json');
          if (index > -1) {
            prefixOverride = prefixOverride.substring(0, index);
          }
          this.layoutPrefixPromise = Promise.resolve(prefixOverride);
        } else if (!this.endpointReference.operationId) {
          // fallback, but this will never happen. do something reasonable if it dos, for some weird reason.
          logger.warn('no operationId, using empty path');
          this.layoutPrefixPromise = Promise.resolve(this.normalizePath(''));
        } else {
          this.layoutPrefixPromise = this.fetchServiceInfo()
            .then((serviceInfo) => {
              const endpoint = serviceInfo && serviceInfo.endpoint;
              const path = endpoint && this.normalizePath(endpoint.path);
              if (!path) {
                logger.error('unable to find operationId for layout prefix:', this.operationId);
              }
              return path;
            });
        }
      }
      return this.layoutPrefixPromise;
    }

    /**
     * provide a way to vbHelper clients to follow external OpenaAPI links (operationRef, x-links, etc),
     * using the same headers/proxy/etc that  was used to fetch the original service definition.
     *
     * @param url
     * @returns {Promise<Object>} JSON Object
     *
     * @deprecated JET components should do the fetch directly, and VB will intercept using UrlMapper
     */
    loadExternalServiceMetadata(url) {
      // use the original behavior if mapping is disabled
      logger.warn('ServiceMetadataProviderHelper.loadExternalServiceMetadata is DEPRECATED! ',
        'It will be removed in a future release.');
      if (this.urlMapperDisabled) {
        let requestInit;
        return Promise.resolve()
          .then(() => {
            if (!url) {
              throw new Error('invalid URL passed to loadExternalServiceMetadata.');
            }
            return this.fetchServiceInfo();
          })
          .then((info) => {
            requestInit = info.requestInit;
            return Utils.getRuntimeEnvironment();
          })
          .then((env) => env.getServiceDefinition(url, requestInit))
          .catch((e) => {
            logger.error('failed to load serviceDefinition for metadata url', url, e);
            throw e; // rethrow
          });
      }

      // just do the fetch using the URL
      return fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }

          logger.error('failed to load serviceDefinition for metadata url', url, response.status);
          throw Error(`unable to load service metadata: status: ${response.status}`);
        });
    }

    /**
     * For a non-BOSS endpoint, remove parameters from the path, includes ending slash, e.g.,
     * dynamicLayouts/insidesales/
     *
     * For a BOSS endpoint, strip off $query and prepend modules/moduleName to the path.
     * For example, given a BOSS endpoint, boss#invoiceModule/List_invoices, and the path,
     * /invoices/$query, the normalized path would be dynamicLayout/self/modules/invoiceModule/invoices/.
     *
     * @param path
     */
    normalizePath(path = '') {
      // Remove parameters in { }
      const parts = path.split('/');
      let newPath = parts.filter((part) => part && part[0] !== '{').join('/');

      // BOSS endpoint only, e.g, boss#invoiceModule/List_invoices
      // Note: Only a BOSS endpoint will have a module name.
      const moduleName = this.endpointReference.moduleName;
      if (moduleName) {
        // strip off $query and everything after
        const index = newPath.indexOf('$query');
        if (index > -1) {
          newPath = newPath.substring(0, index);
        }

        newPath = `${Constants.DefaultPaths.MODULES}${Utils.pathToCamelCase(moduleName)}/${newPath}`;
      }

      const operationPart = Utils.addTrailingSlash(newPath);
      // don't add the service ID, because elastic search and data services may need to share layouts
      // return `${this.layoutRootPrefix}${this.serviceId}${Constants.PATH_SEPARATOR}${operationPart}`;
      return `${this.getLayoutRoot()}${operationPart}`;
    }
  }

  return ServiceMetadataProviderHelper;
});

