'use strict';

define('vb/extensions/dynamic/private/models/layoutResourceExtension',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/containerExtension',
  'vb/extensions/dynamic/private/models/context/layoutResourceExtensionContext',
  'vb/private/stateManagement/layoutMixin',
  'vb/private/translations/bundleUtils',
  'vb/private/utils',
  'vb/errors/httpError',
], (Mixin, ContainerExtension, LayoutResourceExtensionContext, LayoutMixin, BundleUtils, Utils,
  HttpError) => {
  /**
   * LayoutResourceExtension is used to load layout extension related resources, e.g., data-description-x,
   * field-template-x, metadata-rules-x, etc.
   */
  class LayoutResourceExtension extends Mixin(ContainerExtension).with(LayoutMixin) {
    /**
     * LayoutResourceExtension constructor
     *
     * @param extension the extension from which to load the resource
     * @param {String} path the absolute path to the layout resource, e.g., vb/extA/dynamicLayouts/self/employee
     * @param base the base layout resource
     * @param className the class name
     */
    constructor(extension, path, base, className = 'LayoutResourceExtension') {
      super(extension, null, path, base, className);

      this.resourceDescriptor = base.resourceDescriptor;
    }

    static get extensionClass() {
      return LayoutResourceExtension;
    }

    /**
     * Returns the ExtensionContext constructor used to create the '$' expression context.
     * @type {LayoutResourceExtensionContext}
     */
    static get ContextType() {
      return LayoutResourceExtensionContext;
    }

    /**
     * @type {String}
     */
    static get resourceSuffix() {
      return '-x.json';
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor file.
     *
     * @type {String} the descriptor loader function name
     */
    static get descriptorLoaderName() {
      return 'getExtensionTextResource';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions.
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getExtensionModuleResource';
    }

    /**
     * The name of the runtime environment function to be used to load the html.
     *
     * @type {String} the template loader function name
     */
    static get templateLoaderName() {
      return 'getExtensionTextResource';
    }

    /**
     * Return the name of the resource, e.g., data-description-overlay, metadata-rules, etc.
     * @type {String}
     */
    get name() {
      return this.resourceDescriptor.altExtensionResourceName || super.name;
    }

    /**
     * Map the namespace for a layout extension to the extension id.
     *
     * @returns {String}
     */
    get layoutNamespace() {
      return this.extensionId;
    }

    /**
     *
     * @param resourceLocator
     * @returns {*}
     */
    descriptorLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        this.checkResourceExist('json');

        const resource = `${resourceLocator}-x.json`;
        return super.descriptorLoader(resource)
          .then((jsonContent) => {
            this.descriptor = jsonContent; // unparsed descriptor
            return Utils.parseJsonResource(jsonContent, resource);
          });
      })
        .catch((error) => {
          if (HttpError.isFileNotFound(error)) {
            // return an empty JSON so the container can properly initiate but leave
            // descriptor undefined
            return {};
          }
          throw error;
        });
    }

    /**
     *
     * @param resourceLocator
     * @returns {Promise}
     */
    functionsLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        if (this.resourceDescriptor.skipFunctions) {
          return undefined;
        }

        // checkResourceExist is called in super.functionsLoader
        return super.functionsLoader(`${resourceLocator}-x`);
      })
        .catch((error) => {
          if (HttpError.isFileNotFound(error)) {
            return undefined;
          }
          throw error;
        });
    }

    /**
     *
     * @param resourceLocator
     * @returns {Promise}
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    templateLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        if (this.resourceDescriptor.skipTemplate) {
          return undefined;
        }

        this.checkResourceExist('html');

        return super.templateLoader(`${resourceLocator}-x.html`)
          .then((template) => {
            this.template = template;
            return template;
          });
      })
        .catch((error) => {
          if (HttpError.isFileNotFound(error)) {
            return undefined;
          }
          throw error;
        });
    }

    /**
     * Return a promise to load the .html
     *
     * @returns {Promise} a promise that resolve with the html text
     */
    loadTemplate() {
      // Keep a reference of the loading promise so that multiple function can wait
      // on the same promise to be resolved.
      this.loadHtmlPromise = this.loadHtmlPromise || this.templateLoader(this.getResourcePath());

      return this.loadHtmlPromise;
    }

    /**
     * Load the JSON descriptor file and recursively load all the layout resource extensions.
     *
     * @returns {Promise}
     */
    load() {
      this.loadPromise = this.loadPromise || this.loadMetadata()
        .then(() => this.loadContainerDependencies());

      return this.loadPromise;
    }

    /**
     * Load all resources and create the view model.
     *
     * @return {Promise<Object>}
     */
    getViewModel() {
      if (!this.viewModelPromise) {
        this.viewModelPromise = Promise.all([
          this.load(), this.loadFunctionModule(), this.loadTemplate(),
        ])
          // load() eventually triggers loadTranslationBundles().  None of the setup needs to block on the load of the
          // bundles, but we need to make sure the bundles have been loaded before we try to construct $translations.
          .then(() => BundleUtils.whenBundlesReady())
          .then(() => {
            this.viewModel = this.getAvailableContexts();
            return this.viewModel;
          });
      }
      return this.viewModelPromise;
    }

    // eslint-disable-next-line class-methods-use-this
    getScopeResolverMap() {
      return {};
    }
  }

  return LayoutResourceExtension;
});

